.button-upload-empty {
  float: left;
  height: 93px;
  width: 120px;
  border-radius: 8px;
  background-color: #9b63f8;
}

.empty-square-dropzone {
  background-color: rgb(243, 243, 244);
  border-radius: 6px;
  box-sizing: border-box;
  float: left;
  height: 93px;
  margin-left: 5px;
  margin-right: 15px;
  width: 207px;
}

.file-dropzone-square {
  display: flex;
}

.upload-image-logo {
  margin: auto;
}

.file-dropezone {
  cursor: pointer;
}

@media (max-width: 1200px) {
  .button-upload-empty {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }
  .file-dropzone-square {
    flex-direction: column;
  }
}
