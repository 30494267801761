.stripe-container {
  background-color: #f0f0f0;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 100px 0px;
}

.actions {
  display: flex;
  align-items: baseline;
}

.skip {
  padding-left: 10px;
}

.text-block {
  padding-bottom: 32px;
  text-align: left;
}

.text {
  font-family: 'CircularStd Book';
  font-weight: 300;
  font-size: 16px;
  color: #999999;
}

.card-text {
  color: #4d4d4d;
  font-size: 18px;
  line-height: 31px;
  margin-bottom: 0px;
}

.card-title {
  line-height: 20px;
  margin: 0px;
}

.title {
  color: #000000;
  font-size: 20px;
  text-align: center;
}

.stripe-finish-container {
  padding-top: 2rem;
}

.stripe-finish-title {
  margin-bottom: 1rem;
}

.stripe-finish-message {
  text-align: center;
}
