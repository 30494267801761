.wizard-snackbar {
  background-color: rgba(255, 0, 13, 0.88);
}

.wizard__start-date-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wizard__start-date {
  flex: 0 0 61%;
  padding-right: 17px;
}

.wizard__start-time {
  flex: 0 0 36%;
}

.wizard__date-label {
  height: 30px;
  width: 74px;
  color: #c0c0c0;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  align-items: center;
  letter-spacing: 0.55px;
  line-height: 12px;
}

.wizard__start-date-time + .wizard__start-date-time {
  margin-top: 35px;
}
