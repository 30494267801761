.discounts-title-container {
  margin-bottom: 29px;
}

.discounts-title {
  font-family: 'CircularStd Book';
  font-weight: 300;
  font-size: 18px;
}

.discounts-new-btn {
  float: right;
  box-sizing: border-box;
  height: 33px;
  width: 100px;
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  font-size: 16px;
  color: #8727e8;
  text-align: center;
}

.discount-box {
  background-color: white;
  height: 74px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 14px;
}

.discount-box--expandable {
  min-height: 74px;
  height: unset;
}

.icon {
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 23px;
  margin-left: 23px;
  margin-right: 15px;
  padding-top: 5px;
}

.code-container {
  display: inline-block;
  vertical-align: top;
  margin-right: 50px;
}

.discount-box .value {
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
}

.discount-box .label {
  font-family: CircularStd Book;
  font-size: 14px;
  color: #999999;
  margin-top: -8px;
}

.amount-container {
  display: inline-block;
  vertical-align: top;
  margin-right: 50px;
  width: 54px;
}

.uses-container {
  display: inline-block;
  vertical-align: top;
  margin-right: 80px;
  width: 58px;
}

.uses-container .value .used {
  color: #8727e8;
}

.uses-container .value .total {
  color: #ededed;
}

.edit-discount-btn {
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
  width: 75px;
  height: 41px;
  line-height: 41px;
  background-color: #ededed;
  color: #a2a2a2;
  font-family: CircularStd Book;
  font-size: 14px;
  border-radius: 8px;
  text-align: center;
}

.float-right {
  display: inline-block;
}

.inline-block {
  display: inline-block;
}

.ticketing-dialog-content {
  border-radius: 5px;
  width: 500px !important;
}

.ticketing-dialog-body {
  border: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.ticketing-dialog-body .form-wrapper {
  padding: 0;
  text-align: left;
  min-height: 360px;
}

.ticketing-dialog-title {
  border-radius: 3px !important;
  padding-left: 17px !important;
  margin-bottom: 10px !important;
  text-transform: uppercase !important;
  font-family: CircularStd Book !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  display: block !important;
  color: #3c2784 !important;
  background-color: #f5f5f5 !important;
}

.ticketing-dialog-body .label {
  padding-left: 17px;
  text-transform: uppercase;
  font-family: 'CircularStd Book';
  font-size: 11px;
  font-weight: 500;
  color: #999999;
}

.ticketing-dialog-body .input {
  padding-left: 17px !important;
  padding-top: 0;
  border: 0;
  border-bottom: 1px solid #ebebeb;
  padding-left: 0;
  padding-bottom: 5px;
  margin-bottom: 0;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  display: block;
}

.gray-form-field {
  margin: 10px 0 0 0;
}

.ticketing-dialog-body .input::placeholder {
  text-transform: none;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
}

.ticketing-dialog-body .amount {
  display: inline-block;
  width: 49.9%;
  padding-top: 10px;
}

.ticketing-dialog-body .amount:first-child {
  border-right: 1px solid #ebebeb;
}

.ticketing-dialog-body .uses {
  padding-top: 10px;
  margin-bottom: 20px;
}

.ticketing-dialog-body .button-wrapper {
  height: 38px;
  width: 119px;
  margin-left: auto;
  margin-right: auto;
  background-color: #9b63f8;
  border-radius: 3px;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.ticketing-dialog-body .button-label {
  color: #ffffff;
  line-height: 38px;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
}

.discount-box__details {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
}

.discount-box__stats {
  margin-left: auto;
}
