.schedule-title {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  align-self: center;
  /* width: 87px; */
}

.date {
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
}

.register-button {
  height: 40px;
  width: 160px;
  border-radius: 8px;
  border: 2px solid #9b63f8;
  background-color: transparent;
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  outline: none;
  transition: background-color 0.25s;
}

.register-button:hover {
  cursor: pointer;
  background-color: #9b63f8;
  border: 2px solid #9b63f8;
  color: #ffffff;
}

.register-button:focus {
  outline: none;
}

.image-container {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  position: relative;
}

@media screen and (max-width: 1224px) {
  .experience-list-component .image-container {
    grid-gap: 6px;
  }

  .image-container {
    grid-gap: 0;
  }

  .circle::before {
    border-left-color: red;
  }
}

.thumb-image {
  height: 58px;
  width: 87px;
  object-fit: cover;
  border-radius: 8px;
}

.information-container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

@media screen and (max-width: 1224px) {
  .image-container,
  .information-container {
    align-self: flex-start;
  }

  .location-and-time {
    margin-top: 15px;
  }
}

.location-and-time {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
}

.event-name {
  color: #626262;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 3px;
  max-width: 500px;
}

.artist-name {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
}

.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  background-color: #ffffff;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.last-circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  background-color: #ffffff;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-container {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  position: relative;
  /* width: 144px; */
}

.circle::before {
  content: '';
  position: absolute;
  border-left: 1px solid #ebebeb;
  top: 24px;
  z-index: 10;
  height: 112px;
  right: 24px;
}

@media screen and (max-width: 1224px) {
  .circle::before {
    display: none;
  }
}

.circle--ticket-page::before {
  right: 12px;
}

.top-bar {
  height: 80px;
  background-color: #f3f3f4;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 32px;
}

.tiled-icon {
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-icon {
  height: 23px;
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-icon svg {
  height: 25px;
  width: 25px;
}

.tiled-icon svg {
  height: 23px;
  width: 23px;
}

.center {
  display: block;
}

.platform-slash {
  color: #d5d5d5;
}
