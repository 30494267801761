@charset "UTF-8";

/* Font face */

@font-face {
  font-family: 'Aktiv GrotesRegular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/aktivgroteskstdreg-webfont.eot'),
    url('../fonts/aktivgroteskstdreg-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/aktivgroteskstdreg-webfont.woff2') format('woff2'),
    url('../fonts/aktivgroteskstdreg-webfont.woff') format('woff'),
    url('../fonts/aktivgroteskstdreg-webfont.ttf') format('truetype'),
    url('../fonts/aktivgroteskstdreg-webfont.svg#aktivgroteskstdreg-webfont') format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

/*
@font-face {
    font-family: "Aktiv GrotesBold";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/aktivgroteskstdbd-webfont.eot"), url("../fonts/aktivgroteskstdbd-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aktivgroteskstdbd-webfont.woff2") format("woff2"), url("../fonts/aktivgroteskstdbd-webfont.woff") format("woff"), url("../fonts/aktivgroteskstdbd-webfont.svg#aktivgroteskstdbd-webfont") format("svg");
}

@font-face {
    font-family: "Aktiv GrotesMedium";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/aktivgroteskstdmd-webfont.eot"), url("../fonts/aktivgroteskstdmd-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aktivgroteskstdmd-webfont.woff2") format("woff2"), url("../fonts/aktivgroteskstdmd-webfont.woff") format("woff"), url("../fonts/aktivgroteskstdmd-webfont.ttf") format("truetype"), url("../fonts/aktivgroteskstdmd-webfont.svg#aktivgroteskstdmd-webfont") format("svg");
}

@font-face {
    font-family: "Aktiv GrotesLight";
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/aktivgroteskstdlt-webfont.eot"), url("../fonts/aktivgroteskstdlt-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aktivgroteskstdlt-webfont.woff2") format("woff2"), url("../fonts/aktivgroteskstdlt-webfont.woff") format("woff"), url("../fonts/aktivgroteskstdlt-webfont.ttf") format("truetype"), url("../fonts/aktivgroteskstdlt-webfont.svg#aktivgroteskstdlt-webfont") format("svg");
}

@font-face {
    font-family: "Aktiv GrotesItalic";
    font-style: italic;
    font-weight: normal;
    src: url("../fonts/aktivgroteskstdit-webfont.eot"), url("../fonts/aktivgroteskstdit-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aktivgroteskstdit-webfont.woff") format("woff"), url("../fonts/aktivgroteskstdit-webfont.woff2") format("woff2"), url("../fonts/aktivgroteskstdit-webfont.ttf") format("truetype"), url("../fonts/aktivgroteskstdit-webfont.svg#aktivgroteskstdit-webfont") format("svg");
}
*/

@font-face {
  font-family: 'futuraStd medium';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/futurastd-medium-webfont.eot'),
    url('../fonts/futurastd-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/futurastd-medium-webfont.otf') format('opentype'),
    url('../fonts/futurastd-medium-webfont.woff') format('woff'),
    url('../fonts/futurastd-medium-webfont.ttf') format('truetype'),
    url('../fonts/futurastd-medium-webfont.svg#futurastd-medium-webfont') format('svg');
}

@font-face {
  font-family: 'CircularStd Book';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/CircularStd-Book.eot'),
    url('../fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd-Book.otf') format('opentype'),
    url('../fonts/CircularStd-Book.woff') format('woff'),
    url('../fonts/CircularStd-Book.ttf') format('truetype'),
    url('../fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
}

@font-face {
  font-family: 'CircularStd Bold';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/CircularStd-Bold.eot'),
    url('../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd-Bold.otf') format('opentype'),
    url('../fonts/CircularStd-Bold.woff') format('woff'),
    url('../fonts/CircularStd-Bold.ttf') format('truetype'),
    url('../fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
}

@font-face {
  font-family: 'CircularStd Medium';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/CircularStd-Medium.eot'),
    url('../fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CircularStd-Medium.otf') format('opentype'),
    url('../fonts/CircularStd-Medium.woff') format('woff'),
    url('../fonts/CircularStd-Medium.ttf') format('truetype'),
    url('../fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
}

button {
  background-color: transparent;
  border-color: transparent;
  border: 0;
  padding: 0;
}
.react-tagsinput-tag {
  background-color: #ccc;
  border-radius: 2px;
  border: 1px solid #ccc;
  color: #000;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.settings-container {
  background-color: #f0f0f0;
  flex-grow: 1;
}

.containers-experiences {
  padding: 50px 0px 0px 0px !important;
}
.container-list-tab {
  display: flex;
  justify-content: left;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
  max-width: 900px;
}

.tab-item-menu {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
  margin: 15px;
}

.file-uploader-container {
  width: 100%;
  float: left;
  margin-left: 33%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.tab-item-menu-selected {
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
  margin: 15px;
  background-color: #f0f0f0;
  border-bottom: solid 2px #4d4d4d;
  border-radius: 0px;
}

.tab-item-menu:hover {
  color: #9b63f8;
  background-color: #f0f0f0;
  border-bottom: solid 2px #9b63f8;
  border-radius: 0px;
}

.container-list {
  display: flex !important;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
  padding: 20px;
}

.container-item {
  width: 152px;
  height: 152px;
  border-radius: 50%;
  margin: 10px;
  margin-bottom: 15px;
  background-color: #000;
}

.name-item {
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
}

.ticket-detail {
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 18px;
}

.ticket-detail--no-margin {
  margin-left: 10px;
}

.btn-ticket-experience {
  cursor: pointer;
  text-align: center;
  height: 62px;
  width: 335px;
  border-radius: 8px;
  background-color: #69d27c;
  margin: auto;
  line-height: 60px;
}

.ticketFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: 24px 21px;
  box-sizing: border-box;
  box-shadow: 6px -34px 62px -45px rgba(0, 0, 0, 0.45);
  z-index: 999;
}

.btn-text-mobile {
  background: none;
  border: unset;
  color: #ffffff;
  cursor: pointer;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: bold;
  height: 100%;
  letter-spacing: 1.24px;
  line-height: 25px;
  width: 100%;
}

.guide-line-label {
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 18px;
}

.ticket-detail-label img {
  vertical-align: sub;
  border: 0 none;
}

.image-list {
  width: 75px;
  height: 75px;
  object-fit: 'cover';
  margin-top: 25%;
  object-position: center center;
}

/* Submit experience */
.festival-wrapper .chip-input-container,
.festival-wrapper .datepicker,
.festival-wrapper input[type='text'],
.festival-wrapper input[type='number'],
.festival-wrapper input[type='email'],
.festival-wrapper select,
.festival-wrapper textarea,
.festival-wrapper .select,
.experience-wrapper .chip-input-container,
.experience-wrapper .datepicker,
.experience-wrapper input[type='text'],
.experience-wrapper input[type='number'],
.experience-wrapper input[type='email'],
.experience-wrapper select,
.experience-wrapper textarea {
  background-color: #ffffff !important;
  border-color: transparent;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  color: #838383;
  outline: none;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 100;
  line-height: 25px;
  margin-bottom: 14px;
  padding: 20px;
  width: 100%;
  transition: border-color 0.25s;
}

.festival-wrapper .select:active,
.festival-wrapper .select[aria-expanded='true'],
.festival-wrapper .select:hover,
.festival-wrapper .select:focus,
.festival-wrapper input:active,
.festival-wrapper input:hover,
.festival-wrapper input:focus,
.festival-wrapper textarea:active,
.festival-wrapper textarea:hover,
.festival-wrapper textarea:focus {
  border-color: #9b63f8;
  border-style: solid;
  border-radius: 8px;
  border-width: 2px;
}

.festival-wrapper select {
  height: 65px;
  line-height: 65px;
  padding: 0 0 0 16px;
}

.festival-wrapper .select__icon {
  height: calc(100% - 14px);
  top: 0;
  right: 14px;
}

.festival-wrapper .select__icon path {
  fill: #b9b9b9;
}

.experience-wrapper select {
  height: 65px;
  line-height: 65px;
  padding: 0 0 0 16px;
}

.experience-wrapper option {
  margin: 0.5rem 0;
}

.experience-wrapper textarea {
  height: 170px;
}

.experience-wrapper input[type='number'].small-placeholder::placeholder,
.experience-wrapper input[type='email'].small-placeholder::placeholder,
.experience-wrapper input[type='text'].small-placeholder::placeholder {
  color: #d5d5d5;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  opacity: 1;
  text-transform: none;
}

.festival-wrapper ::placeholder,
.festival-wrapper span.select__placeholder,
.experience-wrapper input[type='text']::placeholder,
.experience-wrapper input[type='number']::placeholder,
.experience-wrapper input[type='email']::placeholder,
.experience-wrapper textarea::placeholder {
  color: #838383;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 100;
  line-height: 25px;
  opacity: 0.7;
  text-transform: none;
}

.wizard-date-time-picker {
  width: 100%;
}

.wizard-date-time-picker ::before,
.wizard-date-time-picker ::after {
  display: none;
}

.wizard-date-time-picker input.MuiInput-input {
  margin-bottom: 0;
  width: 100%;
  height: 65px;
}

.experience-wrapper .chip-input-container > div > div:nth-child(2),
.experience-wrapper .chip-input-container input[type='text'],
.experience-wrapper .chip-input-container input[type='number'],
.experience-wrapper .chip-input-container input[type='email'] {
  box-shadow: none;
  font-family: 'CircularStd Book' !important;
  font-size: 20px !important;
  font-weight: 100 !important;
  line-height: 25px !important;
  margin-bottom: 0;
}

.experience-wrapper .chip-input-container > div > div:nth-child(2) {
  color: #d5d5d5 !important;
}

.experience-wrapper .chip-input-container input[type='text'] {
  color: #838383 !important;
}

.experience-wrapper span.field-label {
  color: #c0c0c0;
  font-family: 'CircularStd Book';
  font-size: 11px;
  font-weight: bold;
  line-height: 22px;
  text-transform: uppercase;
}

.experience-wrapper .add-field-wrapper {
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 11px;
  font-weight: bold;
  line-height: 22px;
}

.experience-wrapper .file-uploader h3 {
  color: #d5d5d5;
}

/* Description page */

.description-wrapper.experience-wrapper .category-container {
  display: flex;
  margin-top: -11px; /* JOAO: Don't know why this is needed */
  margin-left: -11px;
}

.description-wrapper.experience-wrapper .category-container select {
  flex: 1 1 50%;
}

.description-wrapper.experience-wrapper .category-container select:last-child {
  margin-left: 10px;
}

.description-wrapper.experience-wrapper .checkboxes-container {
  display: flex;
  margin-bottom: 14px;
  margin-top: 32px;
}

.description-wrapper.experience-wrapper .checkboxes-container .single-check {
  flex: 1 1 33.3%;
  margin-top: 0;
}

.description-wrapper.experience-wrapper > input[type='text']:last-child {
  width: 180px;
}

/* Date/Time page */

.datetime-wrapper .datepicker {
  margin-bottom: 34px;
  padding-left: 56px;
}

.datetime-group {
  display: flex;
}

.datetime-group .datepicker-container {
  flex: 1 0 auto;
  position: relative;
}

.datetime-group .datepicker-container svg,
.datetime-group .datepicker-container img {
  position: absolute;
}

.datetime-group .datepicker-container svg {
  bottom: 54px;
  right: 20px;
}

.datetime-group .datepicker-container img {
  bottom: 56px;
  height: 21px;
  left: 20px;
  width: 21px;
}

.datetime-group .datepicker > div:first-child {
  height: auto !important;
  line-height: 25px !important;
}

.datetime-group .datepicker input[type='text'] {
  box-shadow: none;
  color: #838383 !important;
  font-family: 'CircularStd Book' !important;
  font-size: 20px !important;
  font-weight: 300 !important;
  line-height: 25px !important;
  margin-bottom: 0;
}

.datetime-group .datepicker hr {
  border: none !important;
}

.datetime-group .timepicker-container {
  margin-left: 15px;
}

/* Tickets page */

.tickets-wrapper.experience-wrapper .ticket-list .list-title {
  display: flex;
  justify-content: space-between;
}

.tickets-wrapper.experience-wrapper .ticket-list .list-title > div {
  flex: 0 0 15%;
}

.tickets-wrapper.experience-wrapper .ticket-list .list-title > div:first-child {
  flex: 0 0 45%;
}

.tickets-wrapper.experience-wrapper .ticket-list .list-item {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.tickets-wrapper.experience-wrapper .ticket-list .list-item > div {
  flex: 0 0 15%;
}

.tickets-wrapper.experience-wrapper .ticket-list .list-item > div:nth-child(2) {
  flex: 0 0 45%;
}

.tickets-wrapper.experience-wrapper .ticket-list .list-item > div:nth-child(4) {
  flex: 0 0 20%;
}

.tickets-wrapper.experience-wrapper .input-icon-container {
  position: relative;
}

.tickets-wrapper.experience-wrapper .input-icon-container.left input[type='text'] {
  padding-left: 30px;
}

.tickets-wrapper.experience-wrapper .input-icon-container.right input[type='text'] {
  padding-right: 37px;
}

.tickets-wrapper.experience-wrapper .input-icon {
  bottom: 34px;
  color: #d5d5d5;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  position: absolute;
}

.tickets-wrapper.experience-wrapper .input-icon.left {
  left: 10px;
}

.tickets-wrapper.experience-wrapper .input-icon.right {
  right: 10px;
}

.ticketing-dialog-title svg {
  cursor: pointer;
  float: right;
}

.ticketing-dialog-body .description {
  border-bottom: 1px solid #ebebeb;
  padding: 18px;
}

.ticketing-dialog-body .visibility {
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  justify-content: space-between;
  padding: 18px;
}

.ticketing-dialog-body label {
  color: #999999;
  font-family: 'CircularStd Bold';
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
}

.ticketing-dialog-body .description label {
  display: block;
}

.ticketing-dialog-body textarea {
  border: none;
  color: #838383;
  font-family: 'CircularStd Book';
  font-size: 14px;
  height: 60px;
  line-height: 20px;
  outline: none;
  margin-top: 10px;
  width: 100%;
}

.ticketing-dialog-body textarea::placeholder {
  color: #aeaeae;
  font-family: 'CircularStd Book';
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
}

.ticketing-dialog-body .visibility-toggle {
  width: 66px !important;
}

.ticketing-dialog-body .visibility-toggle > div:nth-child(2) > div {
  margin: 0 !important;
  padding: 6px 0 !important;
  width: 66px !important;
}

.ticketing-dialog-body .buttons-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 18px;
}

.ticketing-dialog-body .buttons-container > div {
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  justify-content: center;
  line-height: 18px;
  min-width: 84px;
  padding: 8px 12px;
}

.ticketing-dialog-body .buttons-container > div > svg {
  margin-left: 8px;
}

.ticketing-dialog-body .buttons-container > div:first-child {
  border: 1px solid #ebebeb;
  color: #626262;
}

.ticketing-dialog-body .buttons-container > div:last-child {
  background-color: #9b63f8;
  color: #ffffff;
  margin-left: 12px;
}

/* Location page */

.location-wrapper.experience-wrapper .state-zipcode-container {
  display: flex;
  justify-content: space-between;
}

.location-wrapper.experience-wrapper .state-container {
  flex: 0 0 56%;
}

.location-wrapper.experience-wrapper .zipcode-container {
  flex: 0 0 40%;
}

/* Artists page */

.feactirung-input-style {
  box-shadow: 0 0px !important;
}

.artist-wrapper.experience-wrapper .artist-fields {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 28px;
  padding: 27px;
  position: relative;
}

.artist-wrapper.experience-wrapper .add-field-wrapper img {
  margin-right: 16px;
}

.artist-wrapper.experience-wrapper .remove-artist {
  cursor: pointer;
  height: 16px !important;
  position: absolute;
  right: -26px;
  top: 0;
  width: 16px !important;
}

/* Food and Drinks page */

.fooddrinks-wrapper.experience-wrapper .chip-input-container {
  padding: 40px 30px;
}

/* Photos page */

.photos-wrapper.experience-wrapper .upload-file-container {
  background-color: #ffffff;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 14px;
  padding: 20px;
  width: 100%;
}

.photos-wrapper.experience-wrapper input[type='text'] {
  padding-left: 63px;
}

.photos-wrapper.experience-wrapper > div {
  position: relative;
}

.photos-wrapper.experience-wrapper > div > img {
  left: 22px;
  position: absolute;
}

.photos-wrapper.experience-wrapper > div > img.youtube {
  height: 16px;
  top: 24px;
  width: 20px;
}

.photos-wrapper.experience-wrapper > div > img.soundcloud {
  height: 11px;
  top: 26px;
  width: 21px;
}

/* Safety Agreements page */

.experience-wrapper .safetychecks-guidelines {
  background-color: #ffffff;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  margin: 0;
  padding: 24px;
  padding-bottom: 42px;
}

.experience-wrapper .safetychecks-guidelines li {
  border-bottom: 1px solid #d5d5d5;
  color: #838383;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  padding: 24px 0;
}

.experience-wrapper .safetychecks-guidelines li:first-child {
  padding-top: 0;
}

.experience-wrapper .safetychecks-guidelines input[type='checkbox'] + label {
  padding-left: 16px;
}

/* confirm page css */

.confirm-wrapper.experience-wrapper .confirm-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  padding: 24px;
}

.confirm-wrapper .confirm-item {
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  display: flex;
  padding-top: 20px;
  padding-bottom: 16px;
}

.confirm-wrapper .confirm-item:first-child {
  padding-top: 0;
}

.confirm-wrapper .confirm-item > div:last-child {
  display: flex;
}

.confirm-wrapper .confirm-item {
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  display: flex;
  padding-top: 20px;
  padding-bottom: 16px;
}

.confirm-wrapper .confirm-item:first-child {
  padding-top: 0;
}

.confirm-wrapper .confirm-item > div:last-child {
  display: flex;
  flex-direction: column;
}

.confirm-wrapper .confirm-item-title {
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  line-height: 34px;
}

.confirm-wrapper .confirm-item-subtitle {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 14px;
  line-height: 22px;
}

.confirm-wrapper .confirm-item svg {
  flex: 0 0 auto;
  margin-right: 18px;
}

/* common css */

.col-2-left {
  float: left;
  width: 49%;
}

.col-2-right {
  float: right;
  width: 49%;
}

.col-3 {
  width: 56%;
  float: left;
}

.col-1 {
  width: 40%;
  float: right;
}

.upload-file {
  background-color: #fff;
  text-align: center;
}

.upload-file #upload-photo {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.upload-file label {
  cursor: pointer;
  display: inline-block;
  text-indent: -9999px;
  background-position: 7px -803px;
  height: 25px;
  width: 39px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Datepicker */

.ui-widget {
  font-family: 'CircularStd Book';
  border-radius: 2px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr,
.ui-corner-tl {
  border-radius: 2px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  font-weight: normal;
}

.ui-widget-content {
  background: #fff none;
  border-color: #edecec;
}

.ui-widget-header {
  background: #eee none;
  border: 1px solid #e1e1e1;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #eee none;
  color: #000;
}

.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: #9d1c22 none;
  color: #fff;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
.ui-state-highlight {
  background: #f14c54 none;
  border: 1px solid #e1e1e1;
  color: #fff;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #e1e1e1;
}

.ui-widget-header .ui-icon {
  background: transparent url(../images/calendar-arrow.png) 0 -1px;
}

.ui-icon-circle-triangle-e {
  background-position: 0 -23px !important;
}

.ui-widget-header .ui-state-hover,
.ui-widget-header.ui-state-focus {
  background: 0 none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-content .ui-state-focus {
  background: #f14c54 none;
  border-color: #f14c54;
  color: #fff;
}

.ui-widget-header .ui-state-hover {
  background: 0 none;
  border: 0 none;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 2px;
  top: 2px;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 2px;
  top: 2px;
}

.ui-timepicker-standard {
  border: 1px solid #edecec;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: 'CircularStd Book';
  overflow-x: hidden;
  font-size: 14px;
}

body,
html {
  height: 100%;
}

img {
  vertical-align: middle;
  border: 0 none;
}

.overlay {
  z-index: -1;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.96);
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  transition: background-color 0.3s;
  overflow: auto;
}

.popup-show.overlay {
  opacity: 1;
  z-index: 999999;
}

.overlay .form-wrapper {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  margin: 0 auto;
  top: 50%;
  position: relative;
  padding: 28px 46px 42px 46px;
  overflow: hidden;
}

.overlay .form-wrapper .green-button {
  min-width: 100%;
}

.popup-show.overlay .form-wrapper {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
}

/* Switch Button */

.switch {
  position: relative;
  display: inline-block;
  width: 67px;
  height: 32px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 34px;
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  -ms-border-radius: 34px;
  -o-border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 23px;
  width: 23px;
  left: 7px;
  bottom: 4px;
  background-color: white;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -ms-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: #fa555d;
}

.switch input:checked + .slider:after {
  position: absolute;
  content: '';
  left: 14px;
  top: 50%;
  width: 18px;
  height: 17px;
  margin-top: -8px;
  background: transparent url(../images/bg-switch.png) 0 0 no-repeat;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  min-width: 173px;
  width: 100%;
  right: -20px;
  margin: 0;
  background-color: #fff;
  padding: 16px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  list-style-type: none;
  -webkit-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  color: #4f4f4f;
  font-size: 15px;
}

.dropdown:after {
  content: '';
  display: block;
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  border-width: 10px;
  position: absolute;
  top: -20px;
  right: 28px;
}

.form-wrapper {
  background-color: #fff;
  max-width: 433px;
  width: 100%;
  margin: 0 auto;
  color: #878787;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 32px 46px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.red-button {
  background: linear-gradient(45deg, #a572f9 0%, #8ca8fc 75%, #8ca8fc 100%);
  border: 0 none;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: #fff;
  font-size: 13px;
  min-width: 150px;
  text-align: center;
  padding: 22px 18px;
  cursor: pointer;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.red-button:hover {
  background: -moz-linear-gradient(45deg, #f07da8 100%, #f07da8 100%, #f07da8 100%);
  background: -webkit-linear-gradient(45deg, #f07da8 100%, #f07da8 100%, #f07da8 100%);
  background: linear-gradient(45deg, #f07da8 100%, #f07da8 100%, #f07da8 100%);
  background-color: #f07da8;
}

.white-button {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  background-color: #fff;
  padding: 15px 18px;
  min-width: 268px;
  color: #000;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  font-family: 'futuraStd medium';
  line-height: 23px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  transition: background-color 0.3s;
  margin-top: 14px;
  margin-bottom: 40px;
  color: #bbb;
  font-size: 13px;
  border: 1px solid #e9e9e9;
}

.white-button:hover {
  background-color: #000;
  color: #fff;
}

.white-button > .star-icon {
  display: inline-block;
  height: 23px;
  width: 23px;
  background-position: 0 -651px;
}

.green-button {
  background-color: #69d27c;
  min-width: 268px;
  padding: 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font: 23px 'CircularStd Medium';
  display: inline-block;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.green-button:hover {
  background-color: #000;
}

.transparent-btn {
  border: 2px solid #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  padding: 10px 28px;
  font-size: 18px;
}

.transparent-btn:hover {
  background-color: #000;
  color: #fff;
}

.blue-btn {
  background-color: #2656f2;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  font: 23px 'CircularStd Medium';
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.blue-btn:hover {
  background-color: #000;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  padding: 20px 14px;
  box-shadow: none;
  border: 0 none;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  color: #c2c2c2;
  font: 11px 'CircularStd Book';
  font-weight: bold;
  color: #333;
}

input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus {
  outline: none;
}

input[type='submit'] {
  border: 0 none;
  cursor: pointer;
  -moz-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  -webkit-transition: all 0.6s linear;
  -ms-transition: all 0.6s linear;
  transition: all 0.6s linear;
}

input[type='checkbox'] {
  opacity: 0;
  width: 24px;
  height: 24px;
  vertical-align: top;
  margin: 0 24px 0 0;
}

input[type='checkbox'] + label {
  position: relative;
  font-size: 23px;
  vertical-align: middle;
}

input[type='checkbox'] + label:before {
  content: '';
  position: absolute;
  left: -52px;
  top: -2px;
  display: block;
  width: 24px;
  height: 24px;
  background: transparent url('../images/bg-checkboxes.png') -7px -193px no-repeat;
  background-size: 40px auto;
}

input[type='checkbox']:checked + label:before {
  background-position: -7px -226px;
}

textarea {
  resize: none;
}

div [data-reactroot] {
  border-radius: 8px !important;
  margin-top: 19px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
}

#app [data-reactroot] {
  margin-top: 0px;
  padding: 0px;
}

.menuCss {
  width: 100% !important;
  border-bottom: solid 2px transparent !important;
}

.menuCss:hover {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.menuCss:hover > div > div > div:not(#badge) {
  color: #9b63f8 !important;
  border-bottom: solid 2px #9b63f8 !important;
}

select {
  z-index: 99;
  position: relative;
  /*opacity: 0; */
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 10px;
  padding-left: 16px;
}

.category-container select {
  z-index: 99;
  position: relative;
  /*opacity: 0; */
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 22px;
}

select:focus {
  outline: none;
}

.ui-widget select {
  opacity: 1;
}

.select-wrapper {
  position: relative;
}

.custom-select {
  height: 28px;
  line-height: 28px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 93%;
  z-index: 0;
}

.custom-select:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 13px;
  margin-top: -3px;
  background: 0 none;
  border-width: 6px;
  border-style: solid;
  border-color: #d1d1d1 transparent transparent transparent;
  z-index: 99;
}

input[type='radio'] {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-top: 0;
  margin-right: 16px;
}

input[type='radio'] + label {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

input[type='radio'] + label:before {
  display: block;
  position: absolute;
  left: -43px;
  top: 7px;
  width: 22px;
  height: 22px;
  margin-right: 15px;
  background-size: 22px auto;
}

::-webkit-input-placeholder {
  color: #c2c2c2;
  font: 11px 'CircularStd Book';
  font-weight: bold;
  text-transform: uppercase;
}

::-moz-placeholder {
  color: #c2c2c2;
  font: 11px 'CircularStd Book';
  font-weight: bold;
  text-transform: uppercase;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #c2c2c2;
  font: 11px 'CircularStd Book';
  font-weight: bold;
  text-transform: uppercase;
}

:-moz-placeholder {
  color: #c2c2c2;
  font: 11px 'CircularStd Book';
  font-weight: bold;
  text-transform: uppercase;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
}

h4 {
  font-weight: normal;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 34px;
  font-weight: normal;
}

h3 {
  font-size: 23px;
}

h5 {
  font-weight: normal;
}

.clearfix:after,
.clearfix:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.clearfix {
  display: block;
}

a {
  text-decoration: none;
  outline: none;
  color: #9b63f8;
  background-color: transparent;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  -ms-transition: color 0.3s;
  transition: color 0.3s;
  cursor: pointer;
}

.ui-timepicker-standard a {
  transition: none;
}

.sprite-icon {
  background-image: url(../images/img-sprite2x.png);
  background-size: 50px auto;
  background-color: transparent;
  background-repeat: no-repeat;
}

.gray-form-field {
  margin: 30px 0 25px 0;
}

.gray-form-field .gray-input {
  background-color: #f6f6f6;
  padding: 16px 10px;
  margin-top: 7px;
}

.gray-form-field .gray-input:first-child {
  margin-top: 0;
}

.gray-form-field .gray-input ::-webkit-input-placeholder {
  font: 14px 'CircularStd Book';
  font-weight: 500;
  color: #9d9d9d;
  text-transform: none;
}

.gray-form-field .gray-input ::-moz-placeholder {
  font: 14px 'CircularStd Book';
  font-weight: 500;
  color: #9d9d9d;
  text-transform: none;
}

.gray-form-field .gray-input :-ms-input-placeholder {
  font: 14px 'CircularStd Book';
  font-weight: 500;
  color: #9d9d9d;
  text-transform: none;
}

.gray-form-field .gray-input :-moz-placeholder {
  font: 14px 'CircularStd Book';
  font-weight: 500;
  color: #9d9d9d;
  text-transform: none;
}

.gray-form-field .gray-input input {
  border: 0 none;
  background-color: transparent;
  width: 93%;
  padding: 0;
  line-height: 20px;
}

.gray-form-field .gray-input span {
  width: 7%;
  height: 20px;
  float: left;
}

.gray-form-field .gray-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f6f6f6 inset;
  box-shadow: 0 0 0px 1000px #f6f6f6 inset;
}

.email-icon {
  background-position: 0 -177px;
}

.lock-icon {
  background-position: -38px -135px;
}

.user-icon {
  background-position: 0 -135px;
}

.user-icon-mobile {
  margin-top: 8px;
}

.card-icon {
  background-position: -31px -47px;
}

.calender-icon {
  background-position: 0 -50px;
}

.container {
  margin: 0 auto;
}

.container-inner {
  max-width: 900px;
  margin: 0 auto;
}

.experience-container {
  display: flex;
  max-width: 762px;
  margin: 0 auto;
}

.experience-container .experience-navigation {
  flex: 0 0 auto;
  margin-right: 48px;
  margin-top: 114px;
  padding-top: 4px;
  text-align: right;
}

.experience-container .experience-navigation > div {
  border-right: 1px solid #ebebeb;
  color: #999999;
  cursor: pointer;
  font-family: 'CircularStd Book';
  font-size: 14px;
  line-height: 35px;
  padding-right: 12px;
}

.experience-container .experience-navigation .active {
  border-right: 1px solid #9b63f8;
  color: #9b63f8;
}

.experience-container .container-right {
  flex: 1 1 auto;
}

.experience-container .buttons-container {
  display: flex;
}

.experience-container .button {
  align-items: center;
  border: none;
  border-radius: 26px;
  cursor: pointer;
  display: flex;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  height: 50px;
  line-height: 50px;
  margin-right: 11px;
  margin-top: 70px;
  padding: 0 11px;
}

.continueProfile-button {
  align-items: center;
  border: none;
  border-radius: 26px;
  cursor: pointer;
  display: flex;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  height: 50px;
  line-height: 50px;
  margin-right: 11px;
  padding: 0 11px;
  margin-bottom: 30px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.containerInput {
  width: 100%;
}

.min {
  width: 30%;
  float: left;
}
.sel {
  width: 20%;
  float: left;
}

.container-invite {
  float: left;
  margin-bottom: 50px;
  width: 100%;
}

.container-invite.flex {
  display: flex;
  justify-content: space-between;
}

.container-invite.flex .containerInput.min {
  display: flex;
  flex: 1 0;
}

.container-select {
  width: 50%;
  float: left;
}

.container-select-input {
  width: 100%;
  float: left;
  margin-bottom: 28px;
}

.container-save {
  margin-left: 14px;
}
.container-continue {
  margin-left: 5px;
}

.aboutTextarea {
  outline: none;
  width: 90%;
  box-sizing: border-box;
  border-radius: 6px;
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
  border-color: #f3f3f4;
  background-color: #f3f3f4;
  padding-left: 16px;
  padding-top: 16px;
  height: 130px;
}

.artist-fields .aboutTextarea {
  outline: none;
  width: 100%;
  box-sizing: border-box;
  height: 130px;
  border-radius: 6px;
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
  border-color: #f3f3f4;
  background-color: #f3f3f4;
  padding-left: 16px;
  padding-top: 16px;
  height: 130px;
}

.container-select .select-profile {
  width: 80%;
  height: 47px;
  box-sizing: border-box;
  border-color: #f3f3f4;
  background-color: #f3f3f4;
  border-radius: 6px;
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 27px;
}

.container-general {
  width: 100%;
  float: left;
  margin-bottom: 25px;
}

.container-select .labelContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.containerInput .simple-input {
  width: 90%;
  height: 47px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: #f3f3f4;
  background-color: #f3f3f4;
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 27px;
}

.artist-fields .containerInput .simple-input {
  width: 100%;
  height: 54px;
  box-sizing: border-box;
  border-radius: 6px;
  border-color: #f3f3f4;
  background-color: #f3f3f4;
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 27px;
}

.container-select .simple-input {
  width: 80%;
  height: 40px;
  box-sizing: border-box;
  border-color: #f3f3f4;
  border-radius: 6px;
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 27px;
  background-color: #f3f3f4;
}

.container-select select::placeholder,
.container-select input[type='text']::placeholder,
.containerInput textarea::placeholder,
.containerInput input[type='text']::placeholder {
  color: #838383;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  opacity: 0.7;
  text-transform: none;
}

.aboutTextarea textarea:focus,
.container-select select:focus,
.container-select input[type='text']:focus,
.containerInput textarea:focus,
.containerInput input[type='password']:focus,
.containerInput input[type='email']:focus,
.containerInput input[type='number']:focus,
.containerInput input[type='text']:focus {
  box-sizing: border-box;
  border: 1px solid rgba(155, 99, 248, 0.33) !important;
  box-shadow: 0px 0px 0px 3px rgba(155, 99, 248, 0.1) !important;
  border-radius: 8px;
  background-color: #ffffff;
}

.container-select input[type='text'],
.container-select select,
.containerInput input[type='text'] {
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
  height: 47px;
}

.labelContainer {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.experience-container .button:focus {
  outline: none;
}

.experience-container .next-button {
  background-color: #9b63f8;
  color: #ffffff;
  width: 150px;
}

.experience-container .next-button svg {
  margin-right: 10px;
}

.experience-container .preview-button {
  background-color: #d8d8d8;
  justify-content: center;
  width: 82px;
}

.button-settings {
  width: 135px;
  background-color: #69d27c;
}

.title-settings {
  font-size: 20px;
  margin-top: 10px;
}

.text-settings {
  color: #626262;
  font-family: 'CircularStd Book';
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
}

/* Password Settings */
.check {
  height: 24px;
  width: 24px;
  margin-right: 5px;
}

.container-settings {
  text-align: center;
  padding: 30px 0px 50px;
  background-color: #f0f0f0;
}

.settings-form {
  max-width: 900px;
  margin: auto;
}

/* Header */

header {
  padding: 12px 38px 8px 38px;
}

.container-experience-mobile {
  line-height: 0;
  margin-bottom: 2rem;
  width: 95vw;
}

.loader-mobile-container {
  text-align: center;
  width: 100%;
  margin-top: 100px;
}

.header-mobile-avatar {
  display: block;
}
.header-mobile-icon-sidebar {
  display: block;
}

header .container {
  padding: 16px 0 0;
}

header ul {
  margin: 0;
}

header .logo-wrapper {
  float: left;
}

header .logo-wrapper .logo {
  padding-right: 24px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-block;
}

header .logo-wrapper .logo-text {
  display: inline-block;
  padding-left: 15px;
  vertical-align: middle;
}

header .header-right {
  float: right;
}

header .header-right .days-left {
  color: rgba(255, 255, 255, 0.5);
  line-height: 37px;
  display: block;
  margin-right: 15px;
}

header .header-right .user-img {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: block;
}

header .header-right .user-img:hover {
  opacity: 0.6;
}

header .header-right .header-button {
  padding: 5px 12px;
  line-height: 16px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  min-width: 100%;
  margin-top: 5px;
  text-transform: capitalize;
}

header .header-right .header-button.white-button {
  color: #fa555d;
}

header .header-navigation > li {
  list-style-type: none;
  float: right;
}

header .header-navigation > li:first-child {
  margin-left: 0px;
}

header .header-navigation .notification-link {
  margin-left: 3px;
}

header .header-navigation .notification-link .dropdown:after {
  right: 38px;
}

header .header-navigation .dropdown-link {
  position: relative;
}

header .dropdown {
  top: 54px;
}

header .dropdown li {
  margin-top: 16px;
}

header .dropdown li:first-child {
  margin-top: 0;
}

header .dropdown a {
  color: #030303;
}

header .dropdown a:hover {
  color: #e5424a;
}

header .notification-list {
  font-family: 'CircularStd Book';
  font-weight: 300;
  width: 336px;
  right: -30px;
}

header .notification-list a {
  color: #e5424a;
}

header .notification-list a:hover {
  text-decoration: underline;
}

header .empty-notification {
  text-indent: -9999px;
  display: block;
  width: 17px;
  height: 30px;
  line-height: 30px;
  background-position: -32px -170px;
}

header .empty-notification:hover {
  opacity: 0.5;
}

header .notifications-num {
  background-color: #69d27c;
  width: 37px;
  height: 37px;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 19px;
  line-height: 39px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

header .notifications-num:hover {
  background-color: #57b268;
}

header .ticket-num {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #fa555d;
  color: #fff;
  text-align: center;
  font: 15px/21px 'CircularStd Book';
  font-weight: 300;
}

.common-header {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.common-header .container {
  margin-top: -12px;
  padding: 20px 10px 0px 10px;
}

.common-header .logo-wrapper .logo {
  border-right: 1px solid #f7f7f7;
}

.common-header .empty-notification {
  background-position: -2px -1229px;
}

.gray-header {
  background-color: #f6f6f6;
}

.gray-header .logo-wrapper .logo {
  border-right: 1px solid #ececec;
}

.overlay h3 {
  font-size: 30px;
  line-height: 40px;
  color: #000;
  margin: 0;
  font-weight: normal;
}

.overlay .cancel {
  color: #bbb;
  font: 13px 'CircularStd Book';
  font-weight: 500;
  margin-top: 16px;
  display: inline-block;
}

.overlay a.cancel:hover {
  text-decoration: underline;
}

.label {
  color: '#999999' !important;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 25px;
  margin-bottom: 10px;
}

/* Upgrade popup */

#popup-upgrade input[type='radio'] + label:before {
  width: 28px;
  height: 28px;
  background-size: 28px auto;
}

#popup-upgrade input[type='radio']:checked + label:before {
  background-position: 0 -40px;
}

.overlay.popup-upgrade .form-wrapper {
  padding: 0;
  display: table;
  width: 802px;
  max-width: 802px;
}

.upgrade-wrapper #btn-subscribe {
  min-width: 272px;
}

.upgrade-wrapper .popup-img,
.upgrade-wrapper .popup-content {
  display: table-cell;
  vertical-align: top;
}

.upgrade-wrapper .popup-img {
  width: 45%;
  vertical-align: middle;
}

.upgrade-wrapper .popup-img img {
  width: 100%;
  height: 100%;
}

.upgrade-wrapper .form-upgrade {
  padding: 23px 32px 27px 32px;
}

.upgrade-wrapper .form-upgrade .popup-content {
  width: 55%;
}

.upgrade-wrapper .upgrade-options {
  margin-top: 8px;
}

.upgrade-wrapper .package-block {
  padding: 18px 0;
  border-bottom: 1px solid #e8e8e8;
  font-family: 'CircularStd Book';
  font-weight: 500;
}

.upgrade-wrapper .package-block:first-child {
  border-top: 1px solid #e8e8e8;
}

.upgrade-wrapper .package-block h4 {
  font-size: 16px;
  color: #000;
  margin: 0;
  font-weight: normal;
  line-height: 22px;
}

.upgrade-wrapper .package-block span {
  color: #69d27c;
  margin-top: 8px;
  line-height: 22px;
}

.upgrade-wrapper .package-block small {
  color: #fd0814;
  font-size: 12px;
}

.upgrade-wrapper .text-select {
  margin-top: 19px;
  display: block;
  color: #bbb;
  text-transform: uppercase;
  font: 11px 'CircularStd Book';
  font-weight: 500;
}

.upgrade-wrapper .form-footer {
  text-align: center;
  margin-top: 27px;
}

.upgrade-wrapper .form-footer #btn-support {
  margin-top: 14px;
  color: #bbb;
  font-size: 13px;
  border: 1px solid #e9e9e9;
  min-width: 268px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

/* popup subscribe */

.gray-input.card-inp {
  width: 49%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.gray-input.card-inp input {
  width: 85%;
}

.gray-input.card-inp span {
  width: 15%;
}

.card-expiry-month {
  float: left;
}

.card-password {
  float: right;
  margin-left: 1%;
}

.card-field .gray-input:first-child {
  margin-top: 7px;
}

/* Invite Popup */

.overlay .invite-wrapper {
  padding: 25px 21px;
}

.overlay .invite-wrapper h2 {
  color: #000;
  font-size: 30px;
  margin: 0 0 18px 0;
  font-weight: normal;
}

.overlay .invite-wrapper .media-item {
  padding: 22px 0;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.overlay .invite-wrapper .media-item .media-thumbnail {
  display: inline-block;
  margin-right: 18px;
  width: 144px;
  height: 76px;
  flex: 0 0 auto;
}

.overlay .invite-wrapper .media-item .media-thumbnail img {
  width: 100%;
  height: 100%;
  flex: 0 0 auto;
}

.overlay .invite-wrapper .media-item .media-content {
  display: inline-block;
}

.overlay .invite-wrapper .media-item .media-content span {
  color: #000;
}

.overlay .invite-wrapper .media-item .media-content .ticket-update {
  color: #e5424a;
  font-size: 13px;
  margin: 0 0 10px 0;
}

.overlay .invite-wrapper .share-with-frnd {
  padding-top: 18px;
}

.overlay .invite-wrapper .comment-wrapper {
  margin-top: 16px;
  position: relative;
}

.overlay .invite-wrapper .comment-wrapper textarea {
  width: 100%;
  border: 0 none;
  min-height: 204px;
  background-color: #f4f4f4;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font: 16px 'CircularStd Book';
  color: #000;
  padding: 26px 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.overlay .invite-wrapper .comment-wrapper textarea:focus {
  outline: none;
}

.overlay .invite-wrapper .comment-wrapper:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -35px;
  left: 30px;
  border-width: 20px;
  border-style: solid;
  border-color: #f4f4f4 transparent transparent transparent;
}

.overlay .invite-wrapper .btn-facebook {
  margin-top: 18px;
  display: block;
}

/* Your awesome popup */

#popup-payment-success p {
  font: 14px 'CircularStd Book';
  margin: 4px 0 0 0;
  color: #000;
}

#popup-payment-success p:first-child {
  margin: 0;
  font-family: 'CircularStd Book';
  font-weight: 500;
}

#popup-payment-success textarea {
  color: #9a9a9a;
}

#upgrade-subscription .form-wrapper {
  padding: 37px 24px 25px 24px;
}

#upgrade-subscription .package-block {
  padding: 18px 7px;
}

#upgrade-subscription .package-block:first-child {
  border-top: 0 none;
}

#upgrade-subscription .package-block:last-child {
  border-bottom: 0 none;
}

#upgrade-subscription #btn-update-sub {
  background-color: #e8e8e8;
  color: #fff;
  display: block;
  font: 23px 'CircularStd Medium';
  padding: 15px 0;
}

#upgrade-subscription #btn-update-sub:hover {
  background-color: #aaa;
}

#upgrade-subscription .form-footer {
  margin-top: 15px;
  padding: 0 12px;
}

#upgrade-subscription h5 {
  margin: 0 0 12px 12px;
}

/* Continue to cancel */

#popup-continue-cancel .popup-content,
#cancel-membership .popup-content {
  padding: 28px 30px 29px 30px;
}

#popup-continue-cancel {
  font: 15px/27px 'CircularStd Book';
}

.pause-sub-list {
  margin: 29px 0 0 0;
  list-style-type: none;
  padding: 0;
  font-family: 'CircularStd Book';
  font-weight: 500;
}

.pause-sub-list li {
  padding-left: 37px;
  margin-bottom: 3px;
  background: transparent url(../images/bg-tick.png) 0 center no-repeat;
  background-size: 20px auto;
}

#popup-continue-cancel .package-price {
  font: 19px 'CircularStd Book';
}

#popup-continue-cancel .package-price .stroke-text {
  text-decoration: line-through;
  color: #e60000;
}

#popup-continue-cancel .package-price .stroke-text span {
  color: #000;
}

#popup-continue-cancel .package-price .offer-price {
  color: #69d27c;
}

#popup-continue-cancel .form-button-wrap {
  margin-top: 48px;
  text-align: center;
}

#popup-continue-cancel .form-button-wrap .green-button {
  width: 270px;
  min-width: 270px;
  display: block;
  margin: 0 auto;
}

#popup-continue-cancel .form-button-wrap #cancel-subscription {
  margin-top: 12px;
}

/* cancel membership */

#cancel-membership {
  font: 15px 'CircularStd Book';
  color: #808080;
}

#cancel-membership .cancel-reasons {
  margin-top: 57px;
}

#cancel-membership .cancel-reasons .cancel-list {
  margin-bottom: 19px;
}

#cancel-membership input[type='radio'] + label:before {
  top: -3px;
}

#cancel-membership .form-footer {
  margin-top: 58px;
  font: 17px 'CircularStd Medium';
  text-align: left;
}

#cancel-membership .form-footer .red-button {
  padding: 15px 18px;
  min-width: 182px;
  font-size: 17px;
  vertical-align: middle;
}

#cancel-membership .form-footer .btn-nevermind {
  border: 1px solid #e3e3e3;
  padding: 16px 17px;
  color: #bbb;
  vertical-align: middle;
}

.page-container {
  background-color: #f5f5f5;
}

.page-container-flex {
  flex: 1;
}

.cardHeaderSelector {
  padding-right: 0px !important;
}

.cardSelector {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  font-family: 'CircularStd Book';
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px !important;
  border-radius: 8px !important;
  z-index: 1;
  height: 81px;
  width: 476px;
}

.cardSelector :hover {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 6px, rgba(0, 0, 0, 0.04) 0px 4px 8px !important;
  border-radius: 8px !important;
}

.cardSelector div div div:hover {
  box-sizing: none;
  box-shadow: none !important;
}

.cardSelector div div:hover {
  box-sizing: none;
  box-shadow: none !important;
}

.cardSelector div div span:hover {
  box-sizing: none;
  box-shadow: none !important;
}

.cardSelector--hover {
  border: 2px solid transparent;
}

.cardSelector--hover:hover {
  border-color: #9b63f8;
}

.cardSelector--hover .material-icons {
  transition: all 0;
}

.cardSelector--hover:hover .material-icons {
  opacity: 0;
}

.image-card-channel {
  float: left;
  margin-left: 5px;
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center center;
  border-radius: 50px !important;
}

.cardSelector div div img:hover {
  box-sizing: none;
  box-shadow: none !important;
  border-radius: 50px !important;
}

.btn-facebook {
  display: block;
  margin-top: 28px;
}

.btn-facebook:hover {
  opacity: 0.8;
}

.or-divider {
  display: block;
  position: relative;
  background-color: #fff;
  text-transform: uppercase;
  color: #c2c2c2;
  font-family: 'CircularStd Book';
  font-weight: bold;
  margin: 18px 0 38px 0;
}

.or-divider:after,
.or-divider:before {
  content: '';
  display: block;
  width: 42%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 14px;
  background-color: #e1e1e1;
}

.or-divider:after {
  left: auto;
  right: 0;
}

.page-title {
  text-align: center;
  margin: 0;
}

/* Signup Page */
.page-signup {
  background: url(../images/bg-signup.jpg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100vh;
}

.page-signup .body-container {
  height: 100%;
  background-color: #3c2784;
}

.page-signup .form-signup h5 {
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  float: left;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}

.page-signup #popup-forgot-password h5,
.page-signup #new-password h5 {
  text-align: left;
}

.page-signup #popup-signup .form-wrapper {
  padding: 0;
  height: 643px;
  width: 411px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 7.7px 15.1px 26px 0 rgba(0, 0, 0, 0.05);
}

.page-signup .signup-header .logo {
  border: 0 none;
}

.page-signup .signup-header .logo-wrapper {
  float: left;
  line-height: 46px;
}

.page-signup .page-container {
  padding-top: 102px;
}

.page-signup h3 {
  margin: 0 0 45px 0;
  font-weight: normal;
  line-height: 35px;
  text-align: center;
}

.page-signup .form-registration {
  margin: -12px 52px 37px 52px;
}

.page-signup .form-registration .inp-field input,
.page-signup .form-signup .inp-field input {
  border: 0 none;
}

.page-signup .form-registration .inp-field:last-child,
.page-signup .form-signup .inp-field:last-child {
  border-bottom: 0 none;
}

.page-signup .form-header p {
  width: 268px;
  margin: 0 auto 25px auto;
  line-height: 17px;
}

.page-signup .form-footer {
  padding: 0px 52px 0px 52px;
}

.page-signup .form-footer p {
  margin: 0;
  line-height: 17px;
}

.page-signup .form-footer a {
  color: #878787;
  font-family: 'CircularStd Book';
  font-weight: 500;
}

.page-signup .form-footer a:hover {
  color: #000;
}

.page-signup .learn-more {
  padding: 16px 0 40px 0;
  font: 13px 'CircularStd Book';
  font-weight: bold;
  text-align: center;
}

.page-signup .learn-more a {
  color: #fff;
}

.page-signup .learn-more a:hover {
  text-decoration: underline;
}

.page-signin .form-wrapper {
  font-size: 11px;
  text-align: center;
  padding: 0px;
}

.page-signin .form-wrapper .form-head-container {
  padding: 33px 53px 0px 53px;
}

.page-signin .form-header p {
  width: 268px;
  margin: 0 auto 25px auto;
  line-height: 17px;
}

.page-signin .form-registration {
  font-family: 'CircularStd Book';
  margin: -12px 53px 39px 53px;
  border: 1px solid #ddd;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.page-signin .form-registration .inp-field {
  border-bottom: 1px solid #ddd;
}

.page-signin .form-registration .inp-field input {
  padding: 20px 14px 21px 14px;
  border: 0 none;
}

.page-signin .form-registration .inp-field:last-child {
  border-bottom: 0 none;
}

.page-signin .form-footer {
  color: #878787;
  font-family: 'CircularStd Book';
  padding: 0px 52px 20px 52px;
}

.page-signin .form-footer .forget-link {
  padding-top: 2px;
}

.page-signin .form-footer p {
  margin-top: 10px;
  line-height: 17px;
}

.page-signin .form-footer a {
  color: #878787;
  font-family: 'CircularStd Book';
  font-weight: 500;
}

.page-signin .form-footer a:hover {
  color: #000;
}

.overlayh5 {
  font: 11px 'CircularStd Book';
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  margin: 0;
}

.image-container-tickets {
  margin-top: 50px;
  width: 260px;
  float: left;
}
/* Main page CSS */

.profile-content {
  max-width: 455px;
  width: 100%;
}

.profile-content h1 {
  line-height: 50px;
  margin: 40px 0 0 0;
}

.profile-content .slide-buttons {
  margin-top: 35px;
  font-size: 20px;
}

.profile-content .slide-buttons .red-button {
  font-size: 20px;
  line-height: 30px;
  margin-left: 10px;
  padding: 15px 18px;
  vertical-align: top;
}

.main-exp-pages header {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  left: 0;
}

.main-exp-pages header .container {
  padding-top: 28px;
}

.slide {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.slide .slide-controls {
  width: 58px;
  height: 461px;
  position: absolute;
  display: block;
  border: 0px;
  overflow: hidden;
  padding: 0px;
  cursor: pointer;
}

.slide .slide-controls img {
  height: 100%;
  max-width: none;
}

.slide .slide-controls.left img {
  float: right;
}

.slide .slide-controls.left {
  left: 0px;
}

.slide .slide-controls.right {
  right: 0px;
}

.slide .slide-inner {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
}

.slide .slide-inner .slide-img {
  float: left;
  height: 460px;
  width: 680px;
  overflow: hidden;
  position: relative;
}

.slide .slide-inner .slide-img img {
  position: absolute;
  bottom: 0px;
}

.slide .slide-right {
  position: absolute;
  right: -28px;
}

.slide .slide-left {
  position: absolute;
  left: -28px;
}

.slide .profile-content {
  float: right;
  color: #fff;
  margin-top: 38px;
  width: 410px;
}

.slide .slide-title {
  margin: 65px 0 0 0;
  font-family: 'CircularStd Bold';
  font-size: 48px;
  line-height: 60px;
}

.section-slideshow {
  padding-bottom: 60px;
}

.section-slideshow .bx-viewport,
.section-slideshow .slide-bg {
  height: 655px !important;
}

.section-slideshow .slide-bg {
  position: absolute;
  width: 100%;
}

.bx-controls-direction a {
  position: absolute;
  width: 28px;
  text-indent: -9999px;
  display: block;
  height: 100%;
  top: 0;
  z-index: 9999;
}

.bx-controls-direction a.bx-prev {
  left: 0;
}

.bx-controls-direction a.bx-next {
  right: 0;
}

.slideshow {
  height: 100%;
}

.admin {
  background: transparent url(../images/bg-slide1.jpg) 0 0 no-repeat;
  background-size: 100% 100%;
}

.media-facility {
  border-top: 0 none;
  width: 100%;
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
}

.media-facility .media-item {
  background-color: #fff;
  padding: 40px 22px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 1px solid #edecec;
  display: flex;
  gap: 18px;
  align-items: center;
  position: relative;
  min-height: 156px;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  transition: background-color 0.5s;
}
.bottom-shadow {
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.media-facility .media-item.active,
.media-facility .media-item:hover {
  background-color: #f8f8f8;
}

.media-facility .media-item:first-child {
  border-left: 0 none;
}

.media-item .media-content {
  display: inline-flex;
  flex-direction: column;
  max-width: calc(100% - 162px);
}

.media-facility .media-item .media-thumbnail {
  overflow: hidden;
  height: 76px;
  position: relative;
  width: 144px;
  flex: 0 0 auto;
}

.media-facility .media-item .media-thumbnail img {
  width: 144px;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.media-content h4 {
  margin: 0;
  font-size: 19px;
  color: #000;
  margin: 12px 0px -11px 0px;
}

.media-content span {
  color: #9b87f6;
  font-size: 14px;
  font-family: 'CircularStd Book', 'sans sarif';
  display: block;
  margin-top: 3px;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.experience-details.exp-block .exp-content span {
  max-width: 300px;
}

.slide-bg {
  width: 100%;
  opacity: 0;
}

.slide-bg:first-child {
  opacity: 1;
}

.image-experience-profile-mobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* My ticket */

.card-title-mobile {
  padding: 16px 17px 4px 13px !important;
}

.first-divider {
  width: 100%;
  height: 85px;
}

.mobile-card {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  box-shadow: rgba(0, 0, 0, 0) -18px 2px 7px 11px, rgba(0, 0, 0, 0.2) 0px 11px 20px 0px !important;
}

.ticket-wrapper {
  padding: 90px 0 178px 0;
}

.ticket-wrapper .my-tickets-list {
  text-align: center;
  margin-top: 60px;
}

.ticket-wrapper .my-ticket {
  display: inline-block;
  width: 254px;
  background-color: #fff;
  margin: 10px 10px 20px 10px;
  text-align: left;
}

.ticket-wrapper .my-ticket:last-child {
  margin-right: 0;
}

.ticket-wrapper .my-ticket .ticket-details {
  padding: 20px 22px;
  font-size: 12px;
}

.ticket-wrapper .my-ticket .ticket-details h4 {
  margin: 0;
  font-size: 17px;
  font-family: 'CircularStd Medium';
  color: #000;
}

.ticket-wrapper .my-ticket .ticket-details small {
  color: #a5a5a5;
}

.ticket-wrapper .my-ticket .ticket-details .date-time {
  color: #e5424a;
  margin: 15px 0 0 0;
}

.ticket-wrapper .my-ticket .ticket-details .ticket-img {
  min-height: 303px;
  overflow: hidden;
}

/* Ticket Mobile */
.ticket-wrapper-mob {
  background: #8594fb;
  background: -moz-linear-gradient(top, #8594fb 30%, #8594fb 30%, #966df9 100%);
  background: -webkit-linear-gradient(top, #8594fb 30%, #8594fb 30%, #966df9 100%);
  background: linear-gradient(to bottom, #8594fb 30%, #8594fb 30%, #966df9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8594fb', endColorstr='#966df9',GradientType=0 );
}

.backIcon {
  background-image: url(../images/mobile-sprite2x.png);
  background-size: 50px auto;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 37px;
  height: 44px;
  background-position: 0 -101px;
  float: left;
  text-indent: -9999px;
}

.settingsIcon {
  background-image: url(../images/mobile-sprite2x.png);
  background-size: 40px auto;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 40px;
  height: 49px;
  float: right;
  background-position: 0 -34px;
  text-indent: -9999px;
}

.btn-agree {
  padding: 50px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #f8f8f8;
}

.btn-agree .container {
  max-width: 786px;
}

#agree {
  display: inline-block;
  font: 23px 'CircularStd Medium';
  text-transform: capitalize;
  padding: 15px 18px;
  min-width: 158px;
}

.guidelines-list {
  margin: 22px 0 100px 0;
  padding: 14px 0 0 0;
  border-top: 1px solid #edecec;
}

.guidelines-list li {
  list-style-type: none;
  padding: 28px 0;
  border-bottom: 1px solid #edecec;
}

.guidelines-list input[type='checkbox'] + label:before {
  background-image: url(../images/bg-checkboxes-purple.png);
  background-position: 0 0;
  background-size: cover;
}
.guidelines-list input[type='checkbox']:checked + label:before {
  background-position: -31px 0;
}

/* Ticket single */

.ticket-info-details {
  padding: 65px 0;
  background-color: #fff;
}

.ticket-info-details .ticket-item-common {
  display: inline-block;
  margin-left: -4px;
  vertical-align: middle;
}

.ticket-info-details .ticket-item-common h4 {
  margin: 0;
}

.ticket-info-details .ticket-item-common span {
  color: #878787;
}

.ticket-info-details .ticket-list {
  border-top: 1px solid #edecec;
  font-size: 20px;
  margin-top: 20px;
}

.ticket-info-details .who-are-going {
  margin-top: 74px;
}

.invite-friend {
  margin: 0;
  padding: 0;
}

.invite-friend li {
  list-style-type: none;
  float: left;
  width: 75px;
  height: 75px;
  overflow: hidden;
  margin-right: 1px;
}

.invite-friend li img {
  width: 100%;
  height: 100%;
}

.friend-title {
  font: 11px 'CircularStd Book';
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 15px 0;
}

.selected-num:after,
.count-list:before {
  content: '';
  display: block;
  position: absolute;
  right: 17px;
  border-width: 6px;
  border-style: solid;
}

.selected-num {
  position: relative;
  text-align: left;
  padding: 0 15px;
}

.selected-num:after {
  top: 50%;
  border-color: #cacaca transparent transparent transparent;
  margin-top: -4px;
}

.count-list {
  margin: 0;
  list-style-type: none;
  background-color: #cacaca;
  padding: 0;
  position: absolute;
  left: -1px;
  top: 100%;
  width: 103%;
  z-index: 9;
  padding: 18px 0;
  font-size: 18px;
  display: none;
}

.count-list:before {
  top: -12px;
  border-color: transparent transparent #cacaca transparent;
}

.count-list li {
  margin-top: 20px;
  cursor: pointer;
}

.count-list li:first-child {
  margin: 0;
}

.count-list li:hover {
  color: #f14c54;
}

/* Experience profile */

.btn-interested.active .star-icon {
  background-position: -26px -649px;
}

.experience-single .container {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  top: auto;
  position: static;
}

.experience-single .exp-bottom {
  padding-top: 1rem;
  left: 0;
  top: -200px;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  padding-bottom: 0px;
}

.experience-single .exp-bottom .left-content {
  float: left;
}

.experience-single .exp-bottom .media-thumbnail {
  width: 82px;
  height: 64px;
  float: left;
}

.experience-single .exp-bottom .media-thumbnail img {
  width: 100%;
  height: 100%;
}

.experience-single .relative {
  position: relative;
}

.experience-single .red-button {
  font-size: 20px;
  line-height: 22px;
  padding: 16px 18px;
  vertical-align: top;
}

.experience-single h5 {
  font: 11px 'CircularStd Book';
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 28px 0;
  color: #000;
}

.experience-single .event-info {
  margin-top: 45px;
  line-height: 37px;
}

.experience-single .facility-list {
  margin: 0;
  padding: 33px 0 37px 72px;
  border-bottom: 1px solid #edecec;
  font-size: 15px;
}

.experience-single .facility-list li {
  list-style-type: none;
  float: left;
  margin-right: 77px;
}

.experience-single .facility-list li:last-child {
  margin: 0;
}

.experience-single .facility-list .facility-item {
  width: 47px;
  height: 52px;
  display: block;
  margin: 0 auto 18px auto;
  background: transparent url(../images/bg-experience-sprite.png) 0 0 no-repeat;
  background-size: auto 52px;
}

.experience-single .facility-list .facility-wine {
  background-position: -189px 0;
}

.experience-single .facility-list .facility-apartment {
  background-position: -380px 0;
}

.experience-single .facility-list .facility-guest {
  background-position: -562px 0;
}

.experience-single .includes-list {
  margin: 0;
  padding: 0;
}

.experience-single .includes-list li {
  float: left;
  list-style-type: none;
  position: relative;
  padding-left: 10px;
  margin-left: 5px;
}

.experience-single .includes-list li:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  margin-top: -2px;
}

.experience-single .includes-list li:first-child:before {
  display: none;
}

.experience-single .includes-list li:first-child {
  padding: 0;
  margin: 0;
}

.experience-single .who-are-going h5 {
  margin-bottom: 14px;
}

.star-icon {
  display: inline-block;
  height: 25px;
  width: 25px;
  background-position: 0 -649px;
}

.exp-image {
  position: relative;
  height: 724px;
  width: 604px;
}

.exp-image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
}

.small-play-btn {
  text-indent: -9999px;
  display: block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -19px 0 0 -19px;
  background-position: 13px -752px;
  opacity: 0.7;
}

.small-play-btn:hover {
  opacity: 1;
}

.guideline-location-wrapper {
  padding: 25px 25px 0 25px;
  -webkit-box-shadow: 0px 0 29px -12px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0 29px -12px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0 29px -12px rgba(0, 0, 0, 0.3);
}

.guideline-location:last-child {
  border-bottom: 0 none;
}

.guideline-location label,
.guideline-location p {
  display: inline-block;
  margin: 0;
}

.guideline-location label {
  font: 11px 'CircularStd Book';
  font-weight: bold;
  color: #c2c2c2;
  min-width: 92px;
  text-transform: uppercase;
}

.guideline-location a:hover {
  text-decoration: underline;
}

.guideline-location .location-wrapper .event-timing {
  position: relative;
  padding-left: 15px;
  margin-left: 16px;
}

.guideline-location .location-wrapper .event-timing:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  margin-top: -2px;
}

.guideline-location .audio-wrapper {
  float: left;
  width: 75%;
}

.guideline-location .audio-wrapper .audio-item {
  height: 81px;
  overflow: hidden;
}

.guideline-location .audio-wrapper .audio-item a {
  display: block;
}

.guideline-location .audio-wrapper .audio-item img {
  width: 100%;
}

.guideline-location .video-wrapper {
  float: right;
  width: 24%;
  margin-left: 1%;
}

.guideline-location .video-wrapper .video-item {
  position: relative;
  width: 141px;
  height: 80px;
  overflow: hidden;
}

.guideline-location .video-wrapper .video-item img {
  width: 100%;
  height: 100%;
}

.media-wrapper.guideline-location label {
  margin-bottom: 12px;
}

#link-invite {
  display: block;
  height: 100%;
  background-color: #f5f5f5;
  padding: 27%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  transition: background-color 0.5s;
}

#link-invite:hover {
  background-color: #d9cfcf;
}

#link-invite span {
  text-indent: -9999px;
  display: block;
  background-position: -7px -565px;
  height: 35px;
}

.artist {
  background-color: #f5f5f5;
  padding: 45px 55px;
}

.artist h2 {
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 70px;
  font-weight: 500;
  line-height: 77px;
  margin-bottom: 65px;
  margin-top: 0;
}

.artist .artist-wrapper {
  margin-bottom: 100px;
}

.artist .artist-wrapper .artist-img {
  height: 472px;
  flex: 0 0 50%;
}

.artist .artist-wrapper .artist-img img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
  width: 604px;
}

.artist .artist-wrapper .artist-content {
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
}

.artist .artist-wrapper .artist-content h3 {
  color: #b3b3b3;
  font-family: 'CircularStd Medium';
  font-size: 57px;
  font-weight: 500;
  line-height: 67px;
  margin-bottom: 49px;
  margin-left: -123px;
  margin-top: 34px;
  max-width: 684px;
  width: 100%;
}

.artist .artist-wrapper .artist-content p {
  font-family: 'CircularStd Book';
  font-weight: 300;
  font-weight: 300;
  line-height: 32px;
  margin: 0;
}

.artist .artist-wrapper .artist-content .artist-name {
  margin-bottom: 44px;
}

.artist .artist-wrapper .artist-content .artist-name h4 {
  margin: 0;
  font-family: 'CircularStd Book';
  font-weight: 500;
  font-size: 24px;
  font-weight: 500;
}

.artist .artist-wrapper .artist-content .artist-name a {
  color: #9b63f8;
  font-family: 'CircularStd Book';
  font-size: 18px;
}

.artist .artist-wrapper .artist-content .artist-name a:hover {
  text-decoration: underline;
}

.video-overlay {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.video-block {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 800px;
  max-height: 527px;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%) scale(0, 0);
  -moz-transform: translate(-50%, -50%) scale(0, 0);
  -ms-transform: translate(-50%, -50%) scale(0, 0);
  transform: translate(-50%, -50%) scale(0, 0);
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.video-block iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}

.overlay.popup-show .video-block {
  -webkit-transform: translate(-50%, -50%) scale(1, 1);
  -moz-transform: translate(-50%, -50%) scale(1, 1);
  -ms-transform: translate(-50%, -50%) scale(1, 1);
  transform: translate(-50%, -50%) scale(1, 1);
}

/* Setting CSS */

.setting-container {
  background-color: #f0f0f0;
}

.profile-page-container {
  background-color: #f0f0f0;
  margin-top: -20px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.setting-container .inp-field {
  margin-bottom: 14px;
}

.setting-container label {
  color: #c1c1c1;
  float: left;
  line-height: 28px;
}

.setting-container .setting-blocks {
  border-bottom: 1px solid #f8f8f8;
  padding: 40px 48px 24px 48px;
}

.setting-container .setting-blocks:last-child {
  border: 0 none;
}

.setting-container .setting-blocks h5 {
  color: #aaa;
  font: 11px 'CircularStd Book';
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 19px 0;
}

.setting-container .setting-blocks input,
.setting-container .setting-blocks select {
  border: 0 none;
  background-color: transparent;
  max-width: 495px;
  padding: 5px;
  color: #000;
  font-family: 'CircularStd Book';
  font-weight: 500;
  text-transform: none;
  font-size: 14px;
}

.setting-container .setting-blocks select {
  width: 100%;
}

.setting-container .setting-blocks .inp-field {
  background-color: #f6f6f6;
  padding: 13px 16px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.setting-container .setting-info .inp-select {
  max-width: 175px;
  width: 100%;
  float: left;
}

.setting-container .setting-info .inp-select select {
  width: 69%;
  height: 28px;
}

.setting-container .setting-info .inp-select .custom-select {
  left: 55px;
  width: 72%;
}

.setting-container .setting-info .inp-phone {
  float: right;
  max-width: 347px;
  width: 100%;
}

.setting-container .setting-info .inp-phone input {
  max-width: 249px;
}

.setting-container .setting-notification .notification-item {
  padding: 23px 0;
  border-bottom: 1px dotted #b2b2b2;
  clear: both;
  overflow: hidden;
}

.setting-container .setting-notification .notification-item:first-child {
  padding-top: 0;
}

.setting-container .setting-notification .notification-item label {
  color: #000;
  line-height: 32px;
}

.setting-container .setting-notification .notification-item .switch {
  float: right;
}

.setting-container .setting-notification .save-btn-wrap {
  margin-top: 17px;
}

.setting-container .disable-button {
  background-color: #fff;
  border: 1px solid #f6f6f6;
  color: #e1e1e1;
}

.setting-container .disable-button,
.setting-container .btn-save {
  padding: 7px 10px;
  min-width: 71px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  display: inline-block;
  text-align: center;
  line-height: 18px;
}

.setting-container .disable-button:hover,
.setting-container .btn-save:hover {
  color: #fff;
  background-color: #000;
}

.setting-container .setting-subscription {
  padding: 0;
}

.setting-container .subscription-overview {
  padding: 32px 48px 34px 48px;
  border-bottom: 1px solid #f8f8f8;
}

.setting-container .subscription-overview .subscription-wrapper p {
  margin: 0 0 8px 0;
}

.setting-container .subscription-overview .subscription-text {
  margin: 34px 0 0 0;
  font-size: 11px;
  color: #ababab;
}

.setting-container .subscription-overview .subscription-text a:hover {
  text-decoration: underline;
}

.setting-container .payment-options {
  padding: 33px 48px 41px 48px;
  color: #ababab;
}

.setting-container .payment-options .payment-method {
  float: left;
}

.setting-container .payment-options .payment-method a {
  font-size: 11px;
  margin-top: 10px;
  display: inline-block;
}

.setting-container .payment-options .payment-method a:hover {
  text-decoration: underline;
}

.setting-container .payment-options .payment-method-right {
  float: right;
}

.setting-container .payment-options .payment-img {
  float: left;
  width: 64px;
}

.setting-container .payment-options .payment-info {
  float: left;
  margin-left: 22px;
}

.setting-container .personal-details {
  padding: 33px 48px 30px 48px;
}

.setting-container .personal-details .account-contact {
  margin-top: 34px;
  color: #514f5f;
}

.setting-container .personal-details .account-contact p {
  margin: 8px 0 0 0;
}

.setting-container .personal-details .account-contact .disconnect {
  font-size: 11px;
  margin-left: 12px;
}

.setting-container .personal-details .account-contact .disconnect:hover {
  text-decoration: underline;
}

.setting-container .personal-details .account-contact .mob-number {
  color: #203c80;
  font-size: 18px;
}

.setting-container .personal-details .custom-select {
  left: 30px;
}

.setting-container .personal-details select {
  width: 535px;
  max-width: none;
}

.bread-crumbs li {
  font: 11px 'CircularStd Book';
  font-weight: bold;
  display: inline-block;
  line-height: 16px;
}

.bread-crumbs li.active {
  color: #a5a5a5;
  position: relative;
  padding-left: 5px;
}

.bread-crumbs li.active:before {
  content: '»';
  font: 14px 'CircularStd Book';
  position: relative;
  top: -1px;
  left: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.page-content {
  padding-bottom: 140px;
}

.page-content .page-title {
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 18px;
  line-height: 36px;
  margin: 48px 0 30px 0;
  text-align: left;
}

/* Manage Experience */

.manage-experiences {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 87px 0;
}

.manage-experiences h3 {
  font: 20px 'CircularStd Medium';
  margin: 0;
  text-align: center;
}

.manage-experiences #submit-exp {
  min-width: 116px;
  font: 13px 'CircularStd Book';
  font-weight: 500;
  padding: 12px 18px;
  margin: 22px auto 0 auto;
  display: block;
}

.experience-list .no-experiences {
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
}

.experience-list .no-experiences .button-container {
  text-align: center;
}

.experience-list .no-experiences .button-container button {
  background-color: #9b63f8;
  border: none;
  border-radius: 26px;
  color: #ffffff;
  cursor: pointer;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  height: 52px;
  line-height: 20px;
  margin-top: 21px;
  outline: none;
  text-align: center;
  width: 197px;
}

.experience-list .experience-item {
  padding: 23px 26px;
  margin-bottom: 7px;
  font: 20px 'CircularStd Book';
  font-weight: 500;
  display: table;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  height: 104px;
  width: 1080px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
  border: 2px solid transparent;
  transition: border 0.25s;
}

.experience-list .experience-item:hover {
  border: 2px solid #9b63f8;
}

.experience-list .experience-item:hover {
  border: 2px solid #9b63f8;
}

.experience-list .experience-item small,
.experience-list .experience-item span {
  font-family: 'CircularStd Book';
}

.experience-list .experience-item .exp-thumbnail {
  width: 88px;
  height: 58px;
  display: inline-block;
}

.experience-list .experience-item .exp-thumbnail img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 8px;
}

.experience-list .experience-item .experience-details {
  width: 49%;
  cursor: pointer;
}

.experience-list .experience-item .experience-details .exp-content {
  display: inline-block;
  margin-left: 28px;
  vertical-align: middle;
}

.experience-list .experience-item .experience-details .exp-content .experience-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.experience-list .experience-item .experience-details .exp-content h4 {
  margin: 0;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.experience-list .experience-item .experience-details .exp-content span.festival-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 17px;
  height: 17px;
  margin-left: 10px;
  flex: 0 0 auto;
  background-color: #9b63f8;
  border-radius: 50%;
}

.festival-icon img {
  height: 11px;
  margin-top: 2px;
  width: 6px;
}

.experience-list .experience-item .experience-details .exp-content span {
  color: #bfbfbf;
  font-size: 13px;
  margin-top: -1px;
  display: block;
}

.ticket-pricing .ticket-price {
  color: #69d27c;
}

.experience-list .total-guest {
  color: #ededed;
  font-family: 'CircularStd Book';
}

.experience-list .total-guest .guest-num {
  color: #9b63f8;
  font-family: 'CircularStd Book';
}

/* footer CSS */

.page-footer {
  background-color: #fff;
  padding: 22px 60px 21px 60px;
  color: #848484;
  font-family: 'CircularStd Book';
  font-weight: 300;
  border-top: 1px solid #ededed;
}

.page-footer .footer-navigation {
  margin: 0;
  list-style-type: none;
  float: left;
  padding: 0;
}

.page-footer .footer-navigation li {
  float: left;
  margin-right: 14px;
}

.page-footer .footer-navigation li a {
  color: #848484;
}

.page-footer .footer-navigation li a:hover {
  color: #000;
}

.page-footer .copyright {
  float: right;
}

.mobile-content {
  display: none;
}

.exp-title {
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
  padding: 5px 10px;
}

.exp-artist-name {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  padding-left: 10px;
}
.ticket-detail-label {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31.58px;
}

.exp-description-mob {
  padding: 10px;
}
.exp-description-mob p {
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28.2px;
  white-space: pre-wrap;
}

.datetime-wrapper .repeat-time {
  margin-top: 37px;
}

.datetime-wrapper .repeat-time label {
  font-size: 16px;
}

.datetime-wrapper .repeat-time label a:hover {
  text-decoration: underline;
}

.datetime-wrapper .repeat-time span {
  color: #838383;
}

.datetime-wrapper .repeat-time input[type='checkbox'] {
  margin-right: 7px;
  vertical-align: middle;
}

.datetime-wrapper .repeat-time input[type='checkbox'] + label:before {
  top: -4px;
  left: -35px;
}

.single-check {
  margin-top: 40px;
}

.chip-input-tags {
  box-shadow: 0px 0px 0px 0px;
  -webkit-box-shadow: 0px 0px 0px 0px;
}

.facility-icons input[type='radio'] {
  height: 18px;
  width: 18px;
  margin: 0;
}

.facility-icons input[type='checkbox'] + label:before {
  height: 18px;
  left: -18px;
  width: 18px;
  top: -3px;
  display: block;
  content: '';
  background: none;
  background-color: #fff;
  border: 1px solid #edecec;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}

.facility-icons input[type='checkbox']:checked + label:before {
  content: '✔';
  font-size: 12px;
}

.facility-icons .facility-icons-list {
  border: 1px solid #edecec;
  border-left: 0 none;
  margin-bottom: 57px;
  box-shadow: 1px 1px 4px #eee;
}

.facility-icons .facility-icons-list li {
  width: 25%;
  float: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 1px solid #edecec;
  padding: 24px 4px 17px 12px;
}

.facility-icons .facility-icons-list li label {
  padding-left: 6px;
  font-size: 14px;
}

.facility-icons .facility-icons-list .facility-icon {
  width: 100%;
  height: 45px;
  text-indent: -9999px;
  background-position: 26px -219px;
  margin-bottom: 15px;
}

.facility-icons .facility-icons-list .appartment {
  background-position: 27px -290px;
}

.facility-icons .facility-icons-list .art-gallery {
  background-position: 27px -374px;
}

.facility-icons .facility-icons-list .home,
.facility-icons .facility-icons-list .outdoors {
  background-position: 21px -475px;
}

/* Listen Watch css */

.listenwatch {
  border: 0px;
}

.listenwatch-inner {
  float: left;
  border: 0px;
  padding-right: 35px;
}

.listenwatch-inner-right {
  float: right;
  border: 0px;
}

.listenwatch-inner-right .video-item {
  position: relative;
  width: 141px;
  height: 80px;
  overflow: hidden;
}

.video-only-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  margin-bottom: 8px;
  height: 0;
}

.video-only-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.audio-only-wrapper {
  position: relative;
  padding-bottom: 79px;
  margin-bottom: 8px;
  height: 0;
}

.audio-only-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.listenwatch span {
  color: #9b9b9b;
  font-size: 12px;
  display: block;
  margin-top: 3px;
}

.divMobImage {
  height: 800px;
}

.contestant-info {
  max-width: 70%;
  display: inline-block;
}
.contestant-info h1 {
  font-size: 46px;
  margin: 0;
  line-height: 44px;
}

.rsvp-btn-mob {
  float: right;
  width: 113px;
  height: 113px;
}

.rsvp-btn-mob,
.go-btn-mob {
  border-radius: 50%;
  background: #976efa;
  background: -moz-linear-gradient(45deg, #976efa 0%, #8594fb 81%);
  background: -webkit-linear-gradient(45deg, #976efa 0%, #8594fb 81%);
  background: linear-gradient(45deg, #976efa 0%, #8594fb 81%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#976efa', endColorstr='#8594fb',GradientType=1 );
  text-align: center;
  -webkit-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  display: inline-block;
  color: #fff;
}
.rsvp-btn-mob .rsvp-button-mob {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  vertical-align: middle;
  background: transparent url(../images/ticket-icon.png) center 31px no-repeat;
  background-size: 48px auto;
  padding-top: 70px;
  font-family: 'CircularStd Medium', 'sans-serif', Arial;
}

.rsvp-btn-mob .rsvp-button-going-mob {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  vertical-align: middle;
  background-size: 48px auto;
  font-family: 'CircularStd Medium', 'sans-serif', Arial;
  line-height: 80px;
}
.exp-image-mob {
  height: 250px;
}
.exp-profile {
  font: 18px/45px 'CircularStd Book', 'sans-serif', Arial;
  font-weight: 500;
  padding-bottom: 128px;
}
.exp-content-wrap {
  max-width: 630px;
  margin: -39px auto 0 auto;
  position: relative;
  background-color: #fff;
  border-top: 1px solid #edecec;
  border-bottom: 1px solid #edecec;
}
.exp-content-wrap .exp-profile-content {
  padding: 40px;
  font-family: 'CircularStd Book', 'sans-serif', Arial;
  font-weight: 300;
}
.exp-content-wrap h1 {
  font: 43px/45px 'CircularStd Medium', 'sans-serif', Arial;
  margin: 0;
}
.profile-ticket-details .profile-ticket-block {
  padding: 34px 60px 28px 0;
  border-top: 1px solid #edecec;
  overflow: hidden;
}
.profile-ticket-details .profile-ticket-block:first-child {
  padding-top: 0;
  border-top: 0 none;
}
.profile-ticket-details {
  padding: 32px 0 40px 40px;
  border-top: 1px solid #edecec;
}
.profile-ticket-details .profile-ticket-block label {
  float: left;
  font: 19px/35px 'CircularStd Book', 'sans-serif', Arial;
  font-weight: bold;
  text-transform: uppercase;
  min-width: 69px;
  text-align: right;
  margin-right: 28px;
}
.profile-ticket-details .profile-ticket-block .ticket-detail-info {
  max-width: 431px;
  font-size: 25px;
  line-height: 30px;
  margin-left: 97px;
}
.profile-ticket-details .profile-ticket-block .ticket-detail-info h3 {
  margin: 0;
  font: 25px/30px 'CircularStd Book', 'sans-serif', Arial;
  font-weight: 500;
}
.profile-ticket-details .profile-ticket-block .ticket-detail-info span {
  font: 21px/30px 'CircularStd Book', 'sans-serif', Arial;
  color: #959595;
}

.profile-ticket-details .profile-ticket-block.spriteicon-after:after {
  width: 30px;
  height: 30px;
  background-position: -33px -166px;
  margin-top: -15px;
  visibility: visible;
  right: 28px;
}

.sprite-icon-mob,
.spriteicon-before:before,
.spriteicon-after:after {
  background-image: url(../images/mobile-sprite2x.png);
  background-size: 50px auto;
  background-color: transparent;
  background-repeat: no-repeat;
}

.spriteicon-after:after,
.spriteicon-before:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
}

.clearfix:after,
.clearfix:before {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.spriteicon-after:after,
.spriteicon-before:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
}

.spriteicon-after,
.spriteicon-before {
  position: relative;
}

.spriteicon-after:after {
  left: auto;
  right: 0;
}

.subscribeUpdateBg {
  background: transparent url(../images/img-upgrade.jpg) 0 0 no-repeat;
  background-size: auto 100%;
  float: left;
  height: 572px;
  width: 50%;
}
.subscribeCancelingBg {
  background: transparent url(../images/img-pause.jpg) 0 0 no-repeat;
  background-size: auto 100%;
  float: left;
  height: 572px;
  width: 50%;
}
.subscribeTrialEndedBg {
  background: transparent url(../images/img-trial-ended.jpg) 0 0 no-repeat;
  background-size: auto 100%;
  float: left;
  height: 572px;
  width: 50%;
}

/* Payment MObile CSS */
.page-payment-mob {
  background-color: #f9f9f9;
  font: 18px 'CircularStd Book', 'sans-serif', Arial;
}

.payment-header-mob {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 5px 0px 0px 0px;
  z-index: 99;
}
.payment-header-mob .header-wrapper-mob {
  padding: 5px 0px 10px 0px;
}
.payment-header-mob .link-cancel-mob {
  color: #ababab;
  -moz-transition: color 0.5s;
  -o-transition: color 0.5s;
  -webkit-transition: color 0.5s;
  -ms-transition: color 0.5s;
  transition: color 0.5s;
  font-size: 15px;
  padding-left: 15px;
}
.payment-header-mob .header-wrapper-mob .link-cancel-mob:hover {
  color: #000;
}
.payment-header-mob .header-wrapper-mob h2 {
  text-align: center;
  margin: 0;
  font-size: 22px;
  font-family: 'CircularStd Medium', 'sans-serif', Arial;
  color: #000;
}

.form-payment-mob {
  padding: 60px 15px 60px 15px;
  overflow: scroll;
}
.form-payment-mob input[type='text'] {
  border: 0 none;
  background-color: transparent;
  border-bottom: 2px solid #edecec;
  padding: 0 14px 13px 0;
  font: 18px 'CircularStd Book', 'sans-serif', Arial;
  color: #000;
  margin-bottom: 16px;
}
.form-payment-mob .fieldset-mob ::-webkit-input-placeholder {
  color: #000;
  font: 15px 'CircularStd Book', 'sans-serif', Arial;
  opacity: 0.5;
}

.form-payment-mob .fieldset-mob ::-moz-placeholder {
  color: #000;
  font: 15px 'CircularStd Book', 'sans-serif', Arial;
  opacity: 0.5;
}

.form-payment-mob .fieldset-mob ::-ms-placeholder {
  color: #000;
  font: 15px 'CircularStd Book', 'sans-serif', Arial;
  opacity: 0.5;
}

.form-payment-mob .fieldset-mob ::-o-placeholder {
  color: #000;
  font: 15px 'CircularStd Book', 'sans-serif', Arial;
  opacity: 0.5;
}
.form-payment-mob .col-2 {
  width: 45%;
  float: left;
}
.form-payment-mob .col-2:first-child {
  margin-right: 10%;
}
.form-payment-mob .fieldset-mob {
  margin-top: 8px;
}
.form-payment-mob .fieldset-mob .card_field {
  padding-top: 14px;
}

.save-btn-wrap-mob {
  position: fixed;
  left: 0;
  bottom: -3px;
  width: 100%;
}
.save-btn-wrap-mob #btn-payment {
  width: 100%;
  display: block;
  text-transform: uppercase;
  font: 22px/30px 'CircularStd Book', 'sans-serif', Arial;
  font-weight: bold;
  border-radius: 0;
  padding: 22px 18px;
}

/* Buy Ticket - mobile */
.ticket-item-common-mob {
  display: table-cell;
  vertical-align: middle;
}
.ticket-item-common-mob h4 {
  margin: 0;
  font-size: 23px;
}
.ticket-item-common-mob span {
  color: #878787;
}

@media (max-width: 1317px) {
  .container {
    width: 98%;
  }
  .common-header .container {
    margin-top: -12px;
    padding: 20px 10px 0px 10px;
  }
  .artist .artist-wrapper .artist-img {
    margin-left: 4%;
    margin-right: auto;
  }

  .artist .artist-wrapper .artist-content {
    margin-right: auto;
  }

  .experience-single .facility-list li {
    margin-right: 22px;
  }
}

@media (max-width: 1200px) {
  .slide .slide-title {
    font-size: 68px;
  }
  .slide .profile-content {
    width: 50%;
    max-width: none;
  }
  .slide .slide-inner .slide-img {
    width: 48%;
    max-width: none;
    margin-right: 2%;
  }

  .file-uploader-container {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
}

@media (max-width: '1024px') {
  .media-facility .media-item {
    width: 50%;
    border-top: 1px solid #edecec;
  }
  .setting-container {
    padding: 4px;
  }
  .setting-options,
  .setting-details {
    float: none;
    max-width: 100% !important;
  }
  .setting-container .setting-details {
    margin-top: 20px;
  }
  .setting-blocks {
    padding: 30px 20px;
  }
  .artist .artist-wrapper .artist-content h3 {
    font-size: 44px;
    line-height: 48px;
    margin: 0;
  }
  .artist .artist-wrapper .artist-content .content-details {
    margin-top: 15px;
  }
  .artist .artist-wrapper .artist-content .artist-name {
    margin-bottom: 15px;
  }

  .experience-single .facility-list {
    padding-left: 20px;
  }
  .exp-image {
    min-height: auto;
  }
  .exp-image img {
    width: auto;
  }
  .artist .artist-wrapper .artist-img {
    display: none;
  }
  .artist .artist-wrapper .artist-content {
    width: 90%;
    margin-left: 6%;
  }
  .divMobImage {
    height: 617px;
  }
}

@media (max-width: 767px) {
  header .logo-wrapper {
    float: none;
    text-align: center;
  }
  .mobile-content {
    display: block;
  }
  .page-signup .signup-header {
    padding: 0 5%;
  }
  .page-signup .form-wrapper .form-head-container {
    padding: 41px 20px;
  }
  .page-signup .form-footer {
    padding: 28px 20px 21px;
  }
  .setting-container .subscription-overview,
  .setting-container .payment-options {
    padding: 32px 20px 34px 20px;
  }
  .setting-container .setting-subscription {
    padding: 0;
  }
  .page-footer {
    padding: 28px 20px;
  }
  .experience-list .experience-item .exp-thumbnail {
    display: none;
  }
  .experience-list .experience-item .experience-details .exp-content {
    margin-left: 0;
  }
  .experience-single .exp-bottom .right-content-btn {
    float: none;
  }
  .divMobImage {
    height: 517px;
  }
  .common-header .container {
    margin-top: -12px;
    padding: 20px 10px 0px 10px;
  }
}

@media (max-width: 640px) {
  .setting-container .setting-blocks input,
  .setting-container .setting-blocks select {
    background-color: #fff;
    padding: 10px 5px;
    max-width: 100%;
  }
  .setting-container .setting-info .inp-select,
  .setting-container .setting-info .inp-phone,
  .setting-container .setting-info .inp-phone input {
    max-width: 100%;
    float: none;
    width: auto;
  }
  .setting-container .setting-info .inp-phone input {
    width: 100%;
  }
  .setting-container .setting-info .inp-select .custom-select,
  .setting-container .setting-info .inp-select select {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
  .setting-container .setting-info .inp-select .custom-select {
    left: 0;
    top: 25px;
  }
  .setting-container .personal-details select {
    width: 100%;
  }
  .setting-container .personal-details .custom-select {
    left: 0;
    top: 25px;
    width: 100%;
  }
  .divMobImage {
    height: 517px;
  }
  .subscription-font-body {
    font-size: 13px;
  }
  .pause-sub-list {
    font-size: 14px;
  }
  .common-header .container {
    margin-top: -12px;
    padding: 20px 10px 0px 10px;
  }
  .container-list-tab {
    display: inline-grid;
    justify-content: left;
    margin-top: 30px;
  }
  .min {
    width: 100%;
    float: left;
    margin: 10px;
  }
  .sel {
    width: 100%;
    float: left;
    margin: 10px;
  }
}

@media (max-width: 480px) {
  .divMobImage {
    height: 517px;
  }
  .contestant-info h1 {
    font-size: 28px;
    line-height: 30px;
  }

  .rsvp-btn-mob {
    float: right;
    height: 78px;
    width: 78px;
  }
  .rsvp-btn-mob .rsvp-button-mob {
    font-size: 12px;
    padding-top: 40px;
    background-position: center 26px;
    background-size: 24px auto;
  }
  .exp-image-mob {
    height: 250px;
  }
  .exp-profile {
    line-height: 32px;
  }
  .exp-content-wrap .exp-profile-content {
    padding: 40px 20px 20px 20px;
  }
  .exp-content-wrap h1 {
    font-size: 35px;
    line-height: 37px;
  }
  .profile-ticket-details .profile-ticket-block {
    padding: 20px 30px 20px 0;
  }
  .profile-ticket-details {
    padding: 20px 0 20px 20px;
  }
  .profile-ticket-details .profile-ticket-block label {
    font-size: 16px;
    margin-right: 15px;
    width: 100%;
    float: none;
  }
  .profile-ticket-details .profile-ticket-block .ticket-detail-info {
    max-width: 100%;
    width: 100%;
    float: none;
    margin: 0;
  }
  .profile-ticket-details .profile-ticket-block .ticket-detail-info h3 {
    font-size: 20px;
  }
  .profile-ticket-details .profile-ticket-block .ticket-detail-info span {
    font-size: 15px;
  }
  .profile-ticket-details .profile-ticket-block.spriteicon-after:after {
    right: 0;
  }
  .ticket-item-common-mob h4 {
    font-size: 19px;
  }
  .ticket-item-common-mob span {
    font-size: 15px;
  }
  .subscription-font-body {
    font-size: 12px;
  }
  .pause-sub-list {
    font-size: 13px;
  }
  .subscription-font-title {
    font-size: 18px;
    line-height: 22px;
  }
  .common-header .container {
    margin-top: -12px;
    padding: 20px 10px 0px 10px;
  }
  .min {
    width: 100%;
    float: left;
    margin: 10px;
  }
  .sel {
    width: 100%;
    float: left;
    margin: 10px;
  }
}

@media (max-width: 375px) {
  .divMobImage {
    height: 468px;
  }
  .exp-image-mob {
    height: 250px;
  }
}

@media (max-width: 360px) {
  .payment-header-mob .header-wrapper-mob h2 {
    font-size: 18px;
  }
  .form-payment-mob input[type='text'] {
    font: 16px 'CircularStd Book', 'sans-serif', Arial;
  }
  .min {
    width: 100%;
    float: left;
    margin: 10px;
  }
  .sel {
    width: 100%;
    float: left;
    margin: 10px;
  }
}

@media (max-width: 320px) {
  .divMobImage {
    height: 399px;
  }
  .exp-image-mob {
    height: 250px;
  }
  .subscription-font-body {
    font-size: 12px;
  }
  .pause-sub-list {
    font-size: 12px;
  }
  .pause-sub-list li {
    padding-left: 20px;
    margin-bottom: 5px;
    background-size: 15px auto;
  }
  .subscription-font-title {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .white-button {
    margin-top: 0px;
  }
  .min {
    width: 100%;
    float: left;
    margin: 10px;
  }
  .sel {
    width: 100%;
    float: left;
    margin: 10px;
  }
}

.input-highligh:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: inset 0 0 8px 1px hsla(0, 0%, 85%, 0.75);
  -moz-box-shadow: inset 0 0 8px 1px hsla(0, 0%, 85%, 0.75);
  box-shadow: inset 0 0 8px 1px hsla(0, 0%, 85%, 0.75);
}

.btn-learnmore {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.24) !important;
  -moz-transition: background-color 0.8s;
  -o-transition: background-color 0.8s;
  -webkit-transition: background-color 0.8s;
  -ms-transition: background-color 0.8s;
  transition: background-color 0.8s;
  border-radius: 8px !important;
}

.btn-learnmore:hover {
  background-color: #f07da8 !important;
}

.blurDiv {
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  -webkit-filter: blur(28px);
  -moz-filter: blur(28px);
  -o-filter: blur(28px);
  -ms-filter: blur(28px);
  filter: blur(28px);
  position: absolute;
  top: -4px;
  left: -10px;
  height: 100%;
  z-index: -2;
  overflow: hidden;
}

.degradeDiv {
  background-color: #3c2784;
  background-size: 100% 100%;
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.loader-container-settings {
  width: 100%;
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;
}

.guideline-location-link {
  text-decoration: none;
  outline: none;
  color: #9b63f8;
  background-color: transparent;
  -moz-transition: color 0.3s;
  -o-transition: color 0.3s;
  -webkit-transition: color 0.3s;
  -ms-transition: color 0.3s;
  transition: color 0.3s;
}

.guideline-location-title {
  font: 11px 'CircularStd Book';
  font-weight: bold;
  color: #c2c2c2;
  min-width: 92px;
  text-transform: uppercase;
  padding-right: 25px;
}

.guideline-location {
  display: inline-block;
  margin: 0;
  border-top: 1px solid #edecec;
  font-size: 20px;
  padding: 32px 0;
  width: 100%;
}

.locationMap {
  height: 150px;
}

.topbar {
  position: fixed;
  padding-bottom: 1rem;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  font-size: 22px;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
}

.hide {
  top: -100%;
}

.contestant-datetime {
  margin: 0;
  padding: 0;
  color: #b9b9b9;
  text-transform: uppercase;
  font: 12px 'CircularStd Book';
  font-weight: 500;
}
.contestant-datetime li {
  float: left;
  position: relative;
  padding: 0 8px;
  list-style-type: none;
}
.contestant-datetime li:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: -2px;
  width: 4px;
  height: 4px;
  background-color: #b9b9b9;
  border-radius: 50%;
  margin-top: -2px;
}
.contestant-datetime li:first-child {
  padding-left: 0;
}
.contestant-datetime li:first-child:before {
  display: none;
}

.tagDetails {
  margin: auto !important;
  padding-bottom: 10px;
  width: 48px;
  height: 52px;
  display: block;
  margin: 0 auto 18px auto;
  background: transparent url(../images/bg-experience-sprite.png) 0 0 no-repeat;
  background-size: auto 52px;
  margin-right: 77px;
  font-size: 15px;
}

.foodSpan {
  background: transparent url(../images/ico-light-snacks2x.png) 0 0 no-repeat;
  background-size: auto 52px;
  width: 48px;
}
.drinkSpan {
  background: transparent url(../images/ico-wine2x.png) 0 0 no-repeat;
  background-size: auto 52px;
  width: 32px;
}

.beerSpan {
  background: transparent url(../images/ico-beer2x.png) 0 0 no-repeat;
  background-size: auto 52px;
}

.teaSpan {
  background: transparent url(../images/ico-tea2x.png) 0 0 no-repeat;
  background-size: auto 59px;
  background-position: 0px -4px;
  width: 49px;
}

.locationSpan {
  background: transparent url(../images/ico-location2x.png) 0 0 no-repeat;
  background-size: 47px 52px;
}

.peopleSpan {
  background: transparent url(../images/ico-guests2x.png) 0 0 no-repeat;
  background-size: auto 52px;
  width: 42px;
}

.gradient-btn {
  background: -moz-linear-gradient(45deg, #a572f9 0%, #8ca8fc 75%, #8ca8fc 100%);
  background: -webkit-linear-gradient(45deg, #a572f9 0%, #8ca8fc 75%, #8ca8fc 100%);
  background: linear-gradient(45deg, #a572f9 0%, #8ca8fc 75%, #8ca8fc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a572f9', endColorstr='#8ca8fc',GradientType=1 );
  border: 0 none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  color: #fff;
  font-size: 13px;
  min-width: 150px;
  text-align: center;
  padding: 22px 18px;
  cursor: pointer;
  display: inline-block;
  min-width: 187px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: background 0.6s;
  -o-transition: background 0.6s;
  -webkit-transition: background 0.6s;
  -ms-transition: background 0.6s;
  transition: background 0.6s;
}

.gradient-btn:hover {
  background: -moz-linear-gradient(45deg, #f07da8 100%, #f07da8 100%, #f07da8 100%);
  background: -webkit-linear-gradient(45deg, #f07da8 100%, #f07da8 100%, #f07da8 100%);
  background: linear-gradient(45deg, #f07da8 100%, #f07da8 100%, #f07da8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f07da8', endColorstr='#f07da8',GradientType=1 );
}

.btn-back-mob {
  position: absolute;
  top: 25px;
  left: 25px;
  background-image: url(../images/backIcon.png);
  background-repeat: no-repeat;
  background-size: 25px;
  width: 42px;
  height: 42px;
  display: block;
  transition: all 0.5s;
}

.btn-share-mob {
  position: absolute;
  top: 18px;
  right: 25px;
  background-image: url(../images/shareIcon.png);
  background-repeat: no-repeat;
  background-size: 23px;
  width: 42px;
  height: 42px;
  display: block;
  transition: all 0.5s;
  display: none;
}

.mediaQueryDiv {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
}

.gravatarShareMob {
  width: 100%;
  background: transparent url(../images/sunrise-bg.png) center center no-repeat;
  background-size: contain;
  display: table;
  margin: 15px 0;
  height: 100px;
}

.share-close-icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  transition: opacity 0.5s;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #9d78f9;
  color: #fff;
  text-align: center;
  font-family: 'CircularStd Book';
  font-weight: 300;
  font-size: 20px;
}

.btnFaceShareMob {
  background-image: url(../images/btn-post-fb2x.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  width: 100%;
  max-width: 800px !important;
}
.btnFacebookModal {
  border: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 13px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  font-size: 13px !important;
  font-weight: inherit;
  position: relative;
  vertical-align: middle;
  z-index: 1;
  height: 54px;
  width: 100%;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background-color: rgb(37, 86, 241);
  text-align: center;
  color: rgb(249, 253, 249);
  fill: rgb(249, 253, 249) !important;
  text-transform: uppercase;
  line-height: 43px;
  padding-left: 10%;
}

.tg-checkbox {
  display: flex;
  position: relative;
  margin-bottom: 60px;
}

.tg-checkbox input[type='checkbox'] {
  display: block;
  height: 24px;
  margin: 0;
  opacity: 0;
  width: 24px;
  z-index: 10;
}

.tg-checkbox input[type='checkbox'] + label {
  font-family: 'CircularStd Book';
  color: #838383;
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
  padding: 0;
  padding-top: 3px;
  padding-left: 10px;
  position: relative;
}

.tg-checkbox input[type='checkbox'] + label:before {
  background: none;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 7px;
  display: block;
  height: 22px;
  left: -24px;
  position: absolute;
  top: 0;
  width: 22px;
}

.tg-checkbox input[type='checkbox']:checked + label:before {
  border: none;
  background-color: #9b63f8;
  height: 24px;
  width: 24px;
}

.tg-checkbox:not(.checked) .check-icon {
  display: none !important;
}

.tg-checkbox.checked .check-icon {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
}

input[type='text'].tg-text-input {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  color: #838383;
  font-family: 'CircularStd Book';
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  padding: 12px 10px;
  width: 180px;
}

.password-protected-content > div {
  border-radius: 3px !important;
  height: 100%;
}

input.input-error,
select.input-error,
span.input-error,
textarea.input-error {
  border: rgba(255, 103, 103, 0.4) 1px solid !important;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 3px rgba(255, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #ffffff !important;
}

select.input-disabled,
input.input-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.select-title-channel {
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-bottom: 40px;
}

.container-sign-up {
  float: left;
  width: 89%;
  padding-left: 25px;
}

.label-sign-up {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 25px;
  margin-bottom: 10px;
}

.tab {
  float: left;
  margin: 5px;
  color: #bdbdbd;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
}

.tabSelected {
  float: left;
  margin: 5px;
  color: #3c2784;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 23px;
}

.p-sign-up {
  color: #878787;
  font-family: 'CircularStd Book';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  font-weight: normal;
  padding: 15px 48px 0px 48px;
}

.footer {
  float: left;
  margin-top: 45px;
  width: 100%;
}

.containerTabs {
  float: left;
  margin-top: 15px;
}

.profile-description {
  color: #4d4d4d;
  font-size: 18px;
  line-height: 32px;
}

.discount-modal-error {
  color: red;
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
}

.festival-dashboard__experience-name {
  color: black;
  font-family: 'CircularStd Book';
  font-size: 16px;
}

.select__paper {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 0%), 0px 8px 10px 1px rgb(0 0 0 / 4%),
    0px 3px 14px 2px rgb(0 0 0 / 4%) !important;
}

.discounts--empty-state {
  align-items: center;
  color: #c3c3c3;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.discounts--empty-state a.discounts-new-btn {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  float: unset;
  margin-left: 15px;
  padding: 5px;
  width: auto;
}

.dashboard table tr:last-of-type td {
  border-bottom: 0;
}

li.festival-checkout__checkbox-container {
  padding-left: 0;
}

li.festival-checkout__checkbox-container:hover {
  padding-left: 0;
  background-color: transparent;
}

li.festival-checkout__checkbox-container:hover .MuiIconButton-label {
  color: #9b63f8;
}

.ticket-detail-label img {
  margin-right: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.experience-list-component:last-child .circle::before {
  display: none;
}

.festival-link {
  color: inherit !important;
  text-decoration: none;
}

a.festival-link:hover {
  text-decoration: none;
}

.without-padding-bottom {
  /* !important needed because of CSS-in-JS */
  padding-bottom: 0 !important;
}

.festival-wrapper .wizard__start-date-time input[type='text'].date-time-input {
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 0;
}

.festival-wrapper .wizard__start-date-time input[type='text'].date-time-input:nth-child(1) {
  text-align: right;
}

.main-page-container .bottom-shadow {
  box-shadow: none;
}

.file-uploader-wrapper,
.file-image-wrapper {
  height: 326px !important;
  max-width: 575.5px;
}
