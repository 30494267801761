.button-all {
  cursor: pointer;
}

.button-green {
  background-color: #69d27c;
  border: 0;
  border-radius: 8px;
  color: #ffffff;
  font-family: 'CircularStd Bold';
  font-size: 14px;
  letter-spacing: 1.24px;
  line-height: 25px;
  opacity: 1;
  text-align: center;
  transition: 2ms;
}

.button-transparent {
  background: transparent;
  border: 2px solid #d5d5d5;
  border-radius: 17.5px;
  color: #4d4d4d;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  padding: 0.4rem 1rem;
}

.button-purple-square {
  background-color: #9b63f8;
  border: 0;
  border-radius: 8px;
  color: #ffffff;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 300;
  padding: 0.4rem 1rem;
}

.button-green:hover,
.button-green:active {
  opacity: 0.5;
}

.button-transparent:active,
.button-transparent:hover {
  opacity: 0.7;
}

.button-purple-pill {
  border-radius: 26.5px;
  background-color: #9b63f8;
  border: 0;
  color: #ffffff;
  font-family: 'CircularStd Book';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}
