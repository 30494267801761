.relive-form-container {
  margin: auto;
  width: 40rem;
}

.relive-form-title {
  color: #000000;
  font-family: 'CircularStd Book';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0.55px;
  line-height: 23px;
}

#relive-form-input {
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border: 0;
  margin-bottom: 1rem;
}

#relive-form-input::placeholder {
  color: #999999;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  text-transform: capitalize;
}

.relive-form-select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 22px;
  color: #999999;
  position: relative;
  padding: 20px 14px;
  width: 100%;
  z-index: 99;
}

.relive-form-button {
  margin-top: 2rem;
  padding: 1rem;
}

.relive-form-form {
  margin-top: 3rem;
}

.relive-form-button-content {
  display: flex;
}

.relive-form-button-content span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
}
