.breadcrumb-container {
  display: flex;
  padding: 1rem 0;
  width: max-content;
}

.breadcrumb-step-container {
  display: flex;
}

.breadcrumb-step-wrapper {
  display: flex;
  flex-direction: column;
  height: 4.5rem;
  position: relative;
  width: 7rem;
}

.breadcrumb-button-background {
  background-color: #d5d5d5;
}

.breadcrumb-button-background-selected {
  background-color: #9b63f8;
}

.breadcrumb-button {
  border: none;
  border-radius: 38px;
  color: #fff;
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: bold;
  height: 38px;
  margin: 0 auto;
  width: 38px;
}

.breadcrumb-label {
  bottom: 0;
  font-family: 'CircularStd Book';
  font-size: 15px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  right: 0;
  text-align: center;
}

.breadcrumb-label-color {
  color: #999999;
}

.breadcrumb-label-color-selected {
  color: #9b63f8;
}

.breadcrumb-divisor {
  border-bottom: 1px solid #d5d5d5;
  left: -15px;
  position: absolute;
  right: -15px;
  top: 20%;
}

.breadcrumb-divisor-wrapper {
  position: relative;
  width: 70px;
}
