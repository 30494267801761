.checkout-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
}

.checkout-page-container > div {
  width: 100%;
}

.ticket-list .ticket-item-name {
  vertical-align: middle;
}

.ticket-list .ticket-item-name h5 {
  margin: 0;
  color: #676767;
  font-family: 'Aktiv GrotesRegular';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 17px;
}

.ticket-select-list-wrapper {
  margin: 52px auto 0 auto;
  width: 39rem;
}

.ticket-list-titles span {
  color: #cacaca !important;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
}

.ticket-select-description-title {
  width: 62%;
}

.ticket-select-qty-title {
  width: 18%;
  text-align: left;
}

.ticket-select-price-title {
  width: 20%;
  text-align: center;
}

.discount-code-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  width: 267px;
  border-radius: 8px;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #999999;
}

@media (max-width: 1224px) {
  .discount-code-container {
    background-color: white;
    margin-bottom: 10px;
  }
}

.discount-code-container .input-discount-code {
  display: inline-block;
  width: 9rem;
  border: none;
  padding: 0;
  line-height: 36px;
  font-family: 'CircularStd Book';
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  background-color: transparent;
}

.discount-code-container .input-discount-code::placeholder {
  text-transform: none;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}

.discount-code-container .apply-discount-btn {
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  color: #8727e8;
  height: 33px;
  float: right;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  width: 82px;
}

.discount-label {
  display: block;
  text-align: center;
  font-family: 'CircularStd Book';
  color: #69d27c;
}

.ticket-info-details .ticket-list .ticket-item {
  padding: 2rem 0;
  border-bottom: 1px solid #edecec;
}

.ticket-info-details .ticket-list .ticket-item select {
  background-color: transparent;
  border: 0 none;
  font-size: 20px;
}

.ticket-info-details .ticket-list .ticket-item .original-price {
  display: block;
  font-size: 14px;
  color: #7c7c7c;
  text-decoration: line-through;
}

.ticket-info-details .ticket-list .ticket-item-name {
  width: 62%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.ticket-info-details .ticket-list .num-of-ticket {
  width: 18%;
  text-align: left;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.ticket-info-details .ticket-list .ticket-price {
  width: 20%;
  text-align: center;
}

.ticket-dropdown {
  background-position-x: 100%;
  background-position-y: 2px;
  padding: 0;
  width: 60%;
}

.ticket-info-details .total-wrapper {
  padding-top: 50px;
}

.ticket-info-details .total-wrapper .total-price-wrap {
  float: left;
}

.ticket-info-details .total-wrapper .total-price-wrap .sub-total {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  display: block;
}

.ticket-info-details .total-wrapper .total-price-wrap .total-price {
  color: #9b63f8;
  font-family: 'Roboto';
  font-size: 25px;
  margin-top: 5px;
}

.ticket-info-details .total-wrapper .total-price-wrap .total-price .fee {
  color: #999;
  font-size: 15px;
}

.ticket-info-details .total-wrapper .btn-checkout {
  float: right;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.24px;
  line-height: 25px;
  text-align: center;
  width: 16.5rem;
  height: 3.9rem;
}

.ticket-select-free-label {
  width: max-content;
  color: #c0c0c0;
  font-weight: bold;
  padding: 5px 2rem;
  border: 2px solid #d5d5d5;
  border-radius: 8px;
  line-height: 28px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1px;
}

/* NEW */
.ticket-page-wrapper {
  height: 100vh;
}

.ticket-select-container {
  padding-top: 20px !important;
}

.ticket-select-wrapper {
  margin: auto !important;
  max-width: none !important;
  width: 67% !important;
}

.ticket-select-header {
  padding-left: 10rem;
}

@media (max-width: 1024px) {
  .ticket-page-wrapper {
    overflow: hidden;
  }
}
