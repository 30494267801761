.section-experience-list {
  background: #f5f5f5;
  padding-top: 4rem;
  width: 100%;
}

.container-experiences-list {
  padding: 0;
}

.container-experiences-list .experiences-list-wrapper {
  text-align: center;
}

.festival-experiences .experiences-list-wrapper {
  padding-bottom: 34px;
}

.container-experiences-list .my-ticket {
  display: inline-block;
  width: 254px;
  background-color: #fff;
  margin: 10px 10px 20px 10px;
  text-align: left;
}

.container-experiences-list .my-ticket:last-child {
  margin-right: 0;
}

.container-experiences-list .my-ticket .ticket-details {
  padding: 20px 22px;
  font-size: 12px;
}

.container-experiences-list .my-ticket .ticket-details h4 {
  margin: 0;
  font-size: 17px;
  font-family: 'CircularStd Medium';
  color: #000;
}

.container-experiences-list .my-ticket .ticket-details small {
  color: #a5a5a5;
}

.container-experiences-list .my-ticket .ticket-details .date-time {
  color: #e5424a;
  margin: 15px 0 0 0;
}

.container-experiences-list .my-ticket .ticket-details .ticket-img {
  min-height: 303px;
  overflow: hidden;
}

.experiences-container {
  margin-left: auto;
  margin-right: auto;
}

.experience-title-left {
  display: inline;
}

.experience-list-title {
  padding-bottom: 1rem;
}
