.reset-password-form-wrapper {
  overflow-x: hidden;
}

.reset-password-form {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 7.7px 15.1px 26px 0 rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #878787;
  font-family: 'CircularStd Book';
  font-size: 11px;
  height: 420px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: 411px;
}

.reset-password-form h5 {
  color: #4d4d4d;
  float: left;
  font-family: 'CircularStd Book';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0 0 10px 0;
  text-align: center;
}

.form-head-container {
  padding: 33px 42px 0px 42px;
}

.signup-button {
  border-radius: 8px;
  margin-top: 2rem;
}

.signup-button button {
  background-color: #f07da8 !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  width: 180px !important;
}

@media (max-width: 900px) {
  .form-container {
    height: 100%;
    width: 100%;
  }

  .reset-password-form-wrapper {
    height: inherit;
    width: inherit;
  }

  .reset-password-form {
    border-radius: 0;
    height: 100% !important;
    margin: 0;
    width: inherit;
  }

  .reset-password-form h5 {
    padding-bottom: 0.5rem;
    width: 100%;
  }

  .signup-button button {
    background-color: #9b63f8 !important;
    height: 62px !important;
    width: 188px !important;
  }
}
