.button-upload {
  float: left;
  height: 93px;
  width: 120px;
  border-radius: 8px;
  background-color: #9b63f8;
}

@media (max-width: 1200px) {
  .button-upload {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
  }
}
