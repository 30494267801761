.details-line {
  border-bottom: 1px solid #edecec;
  left: -4%;
  position: absolute;
  right: 0;
}

.details-wrapper {
  padding: 20px 0 40px;
}
