.dashboard-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.dashboard-container--mobile {
  padding: 15px;
}

.dashboard-container--mobile h1 {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  line-height: 1;
  margin: 45px auto;
  max-width: 600px;
  padding: 15px;
  text-align: center;
}

.dashboard {
  background-color: #f5f5f5;
  flex: 1;
}

.experience-info {
  background-color: #ffffff;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: #ebebeb;
  height: 91px;
}

.experience-info-inner {
  padding: 0 38px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.experience-info .experience-img {
  width: 88px;
  height: 58px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: text-bottom;
}

.experience-img img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 4px;
}

.experience-name-wrapper {
  display: inline-block;
}

.experience-name-wrapper .experience-name {
  font-family: 'CircularStd Book';
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
}

.experience-name-wrapper .experience-date {
  font-family: CircularStd Book;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
}

.experience-guests-wrapper {
  display: inline-block;
  margin-right: 50px;
  font-family: CircularStd Book;
  font-weight: 300;
}

.experience-guests-wrapper .pie-chart {
  display: inline-block;
  margin-right: 10px;
  vertical-align: sub;
}

.info {
  display: inline-block;
}

.info .info-values {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 5px;
  color: #4d4d4d;
}

.info .attending-value {
  color: #4d4d4d;
}

.info .total-value {
  color: #999999;
}

.info .info-label {
  color: #999999;
  font-size: 14px;
  line-height: 14px;
}

.info.net-sales {
  margin-right: 50px;
}

.experience-menu {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.13);
  margin-bottom: 2px;
  height: 50px;
}

.experience-menu-inner {
  padding: 0 38px;
  margin-left: auto;
  margin-right: auto;
}

.experience-menu .menu-items {
  display: flex;
  padding-top: 10px;
  width: 100%;
}

.menu-items .menu-item {
  font-family: CircularStd Book;
  font-weight: 500;
  font-size: 12px;
  color: #999999;
  margin-right: 20px;
  text-transform: uppercase;
}

.menu-items .menu-item--float-right {
  margin-left: auto;
}

.experience-menu .menu-actions {
  display: inline-block;
  padding-top: 10px;
  margin-left: auto;
}

.experience-menu .action-item {
  display: inline-block;
}

.menu-actions .action-item {
  margin-left: 20px;
}

.action-item .action-item-icon {
  margin-right: 8px;
}

.action-item .action-item-icon-duplicate {
  width: 22px;
  height: auto;
  margin-top: -2px;
  margin-right: 6px;
}

.action-item .action-item-icon-unpublish {
  width: 23px;
  height: auto;
  margin-top: -2px;
  margin-right: 5px;
}

.action-item .action-item-label {
  font-family: CircularStd Book;
  font-weight: 500;
  font-size: 12px;
  color: #999999;
  text-transform: uppercase;
}

.experience-menu .menu-item-selected {
  color: #3c2784;
}

.dashboard-content {
  padding: 27px 0;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
