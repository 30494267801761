.experience-profile-container {
  display: flex;
  justify-content: center;
}

.experience-panel {
  flex: 1;
}

.experience-image-card {
  margin-left: auto;
  margin-right: 4%;
  width: 604px;
}

.content-right {
  font-family: 'CircularStd Book';
  font-weight: 300;
  margin-left: 4%;
  margin-right: auto;
  width: 604px;
}

.banner-section {
  background-size: 100% 100%;
  height: 514px;
  overflow: hidden;
  margin-left: 4%;
  margin-right: auto;
  width: 604px;
}

.banner-section header {
  margin-bottom: 17px;
}

.banner-section .banner-content {
  width: 50%;
  float: right;
}

.banner-section .banner-content .profile-content {
  color: #fff;
  margin-top: 26px;
}

.banner-section .banner-content .btn-interested {
  line-height: 30px;
  color: #9b9b9b;
  font: 20px/30px 'futuraStd medium';
  padding: 18px;
  min-width: 190px;
}

.banner-section .banner-content .red-button {
  line-height: 15px;
  padding: 19px;
  vertical-align: top;
  font: 23px 'CircularStd Bold';
  min-width: 190px;
  margin-left: 3px;
}

.banner-section .banner-content .red-button span {
  font-family: 'CircularStd Book';
}

.experience-profile-relive-button {
  color: #fff;
  display: flex;
  height: 100%;
}

.experience-profile-relive-button div {
  display: flex;
  margin: auto;
}

.experience-profile-relive-button img {
  margin: auto 15px auto 0;
  width: 13px;
}

@media (max-width: 1200px) {
  .experience-profile-container {
    flex-direction: column;
    margin: 0 !important;
  }

  .experience-image-card {
    margin-right: auto;
  }

  .banner-section .banner-content {
    float: none;
    width: 100%;
    max-width: none;
  }
  .banner-section .banner-content .profile-content {
    padding-left: 20px;
  }
  .banner-section .banner-content .profile-content h1 {
    font-size: 54px;
    line-height: 58px;
  }
  .banner-section .banner-content .profile-content .slide-buttons {
    margin-top: 20px;
  }
}
