.avatar {
  float: left;
  margin-right: 10px;
}

.content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  padding: 0 0 30px 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  padding-bottom: 36px;
}

.table th {
  text-align: left;
  line-height: 38px;
  padding-top: 3px;
}

.tableHeading {
  text-align: left;
}

.row {
  border-bottom: 1px solid rgb(224, 224, 224);
}

.table td {
  padding: 15px 0 14px 0;
}

.table td:first-child,
.table th:first-child {
  padding-left: 38px;
}
.table td:last-child,
.table th:last-child {
  padding-right: 4px;
}

.spinner {
  text-align: center;
  width: 100%;
  padding-top: 50px;
}

.tac {
  text-align: center;
}
