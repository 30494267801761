.sidebar-container {
  background-color: #3c2784;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: -2000px;
  padding: 0 1rem;
  position: fixed;
  right: 2000px;
  top: 0;
  transition: all 0.5s ease-in-out;
  z-index: 1000;
}

.sidebar-in {
  left: 0;
  right: 0;
}

.sidebar-content {
  overflow-y: scroll;
}

.sidebar-title {
  margin-bottom: 1rem;
}

.sidebar-title span {
  color: #9b63f8;
  font-size: 16px;
  font-weight: 500;
}

.sidebar-logo {
  margin-bottom: 1.5rem;
}

.sidebar-logo img {
  width: 92px;
}

.sidebar-logo button {
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar-cancel {
  display: flex;
  justify-content: flex-end;
}

.sidebar-cancel button {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 1.5rem;
  padding: 0;
}

.sidebar-channel-container {
  margin-bottom: 1.5rem;
}

.sidebar-channel-container button {
  background: none;
  font-family: 'CircularStd Book';
  border: none;
  color: #c1c3f3;
  cursor: pointer;
  padding: 0;
  font-size: 24px;
}
