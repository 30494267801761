.header-mobile-channel-logo {
  display: flex;
}

.header-mobile-channel-logo img {
  border-radius: 66px;
  display: block;
  height: 66px;
  margin: 2rem auto 2.3rem auto;
  width: 66px;
}

.list-title-mobile {
  text-align: center;
}
