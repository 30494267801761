.ticket-page-container-mob {
  background-color: #f5f5f5;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
}

.ticket-page-info-wrapper-mob {
  flex: 1;
  overflow-y: scroll;
}

.ticket-wrapper-mob {
  padding: 0;
  margin-bottom: 0px;
}

.ticket-footer-mob {
  width: 100%;
  background-color: #fff;
  padding: 24px 0;
  box-sizing: border-box;
  -webkit-box-shadow: 0 -7px 26px 0 rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0 -7px 26px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 -7px 26px 0 rgba(0, 0, 0, 0.09);
  z-index: 999;
}

.ticket-footer-mob .btn-wrap-mob {
  box-sizing: border-box;
  font-size: 20px;
  color: #fff;
}

.ticket-footer-mob .btn-wrap-mob a {
  display: block;
}

.ticket-footer-mob .ticket-btn-mob a {
  float: none;
  color: #fff;
}

.ticket-list-mob {
  font-size: 20px;
  padding: 2.5rem 1rem 1rem 1rem;
  font-family: 'CircularStd', 'sans-serif', Arial;
}

.ticket-list-mob .ticket-item-mob {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  height: 5rem;
  justify-content: space-between;
  margin-bottom: 14px;
  padding: 0 3%;
  -webkit-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px -1px 9px 2px rgba(0, 0, 0, 0.05);
}

.ticket-list-mob .ticket-item-mob select {
  background-color: transparent;
  border: 0 none;
  font-size: 18px;
  width: 100%;
}

.ticket-list-mob .ticket-item-mob select:focus {
  outline: none;
}

.ticket-list-mob .ticket-item-name-wrapper-mob {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex: 0.5;
  justify-self: flex-start;
}

.ticket-item-name-mob {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 17px;
  margin: auto 0;
}

.ticket-list-mob .ticket-price-wrapper-mob {
  flex: 0.35;
  display: flex;
  justify-content: flex-end;
}

.ticket-price-mob {
  margin: auto 1rem auto 0;
  font-size: 18.05px;
  letter-spacing: 0;
  line-height: 22.56px;
}

.ticket-list-mob .num-of-ticket-mob {
  flex: 0.15;
  display: flex;
  justify-content: flex-end;
}

.ticket-page-select {
  height: 2.5rem;
  margin: auto 0;
  padding-left: 5px;
}

.ticket-list-mob .original-price {
  display: block;
  font-size: 14px;
  color: #7c7c7c;
  text-decoration: line-through;
}

.purchase-ticket-button {
  width: 100%;
  height: 3.9rem;
}

.purchase-ticket-button-wrapper {
  padding: 0 1rem;
}
