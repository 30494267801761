.container.page-guideline {
  width: 67%;
  margin: auto;
}

.page-guideline .slide-title {
  max-width: 65%;
  line-height: 57px;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 45px;
  font-weight: 300;
  color: #000000;
}

.page-guideline .guidelines-wrapper {
  margin: 0 6rem;
  padding-bottom: 20px;
}

.page-guideline .guidelines-wrapper h3 {
  color: #000000;
  font-size: 24px;
  margin-top: 0;
}

.page-guideline .btn-agree {
  background-color: #ffffff;
}

.page-guideline .btn-agree .container {
  margin-left: 30%;
}

.page-guideline .guidelines-wrapper .guidelines-list {
  margin-bottom: 50px;
}

.guidelines-button {
  font-size: 14px;
  height: 56px;
  letter-spacing: 1.24px;
  width: 178px;
  margin-bottom: 100px;
}

.guidelines-item-wrapper {
  display: flex;
}

.guidelines-check {
  background-color: #9b63f8;
  border-radius: 4px;
  display: flex;
  height: 27px;
  width: 27px;
}
.guidelines-check img {
  margin: auto;
  width: 50%;
}

.guidelines-item {
  color: #4d4d4d;
  font-size: 23px;
  line-height: 34px;
  padding-left: 2rem;
}

.guidelines-legend {
  font-weight: 500;
}
