.checkout-header-container {
  margin-bottom: 0;
  width: 100%;
}

.checkout-header-container .image-title {
  display: flex;
  min-width: 165%;
  margin-bottom: 45px;
  padding-left: 1rem;
}

.checkout-header-container img {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  width: 238px;
  height: auto;
}

.checkout-header-container h1 {
  line-height: 50px;
  margin: 40px 0 0 0;
}

.checkout-header-image-wrapper {
  height: 142px;
  width: 284px;
}

.checkout-header-image {
  height: 100% !important;
  object-fit: cover;
  object-position: center center;
  margin: 0 !important;
  width: 100% !important;
}

.checkout-header-title {
  color: #000000;
  display: inline-block;
  font-size: 45px;
  font-weight: 300;
  height: min-content;
  margin: auto 0 auto 2.5rem !important;
  max-width: 50%;
}
