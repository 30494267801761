.video-player-container {
  display: flex;
  flex: 1;
}

.video-player {
  margin: 1rem auto;
}

.video-player iframe {
  height: 600px;
  width: 1350px;
}

.video-player-loader {
  margin: 5rem auto;
}

@media (max-width: 1224px) {
  .video-player {
    margin: 10rem auto;
  }

  .video-player iframe {
    height: 150px;
    width: 300px;
  }
}
